export default function beamerInitialize(config, url: string | false = false) {
  if (!config?.product_id) {
    throw Error('Must provide "id". https://app.getbeamer.com/embed');
  }

  (window as any).beamer_config = { ...config };
  const beamerURL = url || 'https://app.getbeamer.com/js/beamer-embed.js';
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.defer = true;
  script.src = beamerURL;
  head.appendChild(script);
}
