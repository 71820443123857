import { useCallback, useContext, useReducer } from 'react';

import { Options } from './ApiData';
import ApiDataContext from './ApiDataContext';

// Define the structure of the state
type State<T> = {
  data: T | null;
  error: any;
  success: boolean | null;
  loading: boolean;
};

// Define the action types
type Action<T> =
  | { type: 'REQUEST_START' }
  | { type: 'REQUEST_SUCCESS'; data: T }
  | { type: 'REQUEST_ERROR'; error: any };

// Initial state with generic data
const INITIAL_STATE = {
  data: null,
  error: null,
  success: null,
  loading: false,
} as State<any>; // Can be State<T> when using generics

// Reducer function with typed state and actions
const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'REQUEST_START':
      return { ...INITIAL_STATE, data: state.data, loading: true };
    case 'REQUEST_SUCCESS':
      return { data: action.data, error: null, success: true, loading: false };
    case 'REQUEST_ERROR':
      return { data: null, error: action.error, success: null, loading: false };
    default:
      throw new Error('Unhandled action type in reducer');
  }
};

// Main hook with proper typing
const useApiFetcher = <T = any>() => {
  const apiData = useContext(ApiDataContext);

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE as State<T>);

  // Fetcher function with typed parameters
  const fetcher = useCallback(
    (
      method: string,
      url: string,
      params?: Record<string, any>,
      options?: Options,
    ): Promise<T> => {
      dispatch({ type: 'REQUEST_START' });

      if (!apiData) {
        const error = new Error('ApiDataContext is null');
        dispatch({ type: 'REQUEST_ERROR', error });
        return Promise.reject(error);
      }

      return apiData
        .fetch(method, url, params, options)
        .then((data: T) => {
          dispatch({ type: 'REQUEST_SUCCESS', data });
          return data;
        })
        .catch((error: any) => {
          dispatch({ type: 'REQUEST_ERROR', error });
          throw error;
        });
    },
    [apiData],
  );

  // Return the typed fetcher and state
  return [fetcher, state] as [typeof fetcher, State<T>];
};

export default useApiFetcher;
