import React from 'react';

type BlockAdminContextType = {
  state: { display: boolean };
  dispatch: React.Dispatch<any>;
};

const BlockAdminContext = React.createContext<BlockAdminContextType>({
  state: { display: false },
  dispatch: () => {},
});

export function useBlockAdminContext() {
  return React.useContext(BlockAdminContext);
}

export default BlockAdminContext;
