import { SideNav, SideNavMenu, Tag } from '@carbon/react';
import styled from 'styled-components';

import { unit } from 'theme';

import {
  DELEGATED_ACCESS_HEADER_HEIGHT_VALUE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_VALUE,
} from '../Header/Header.styles';

export const TopMenu = styled(SideNavMenu)<{ dir: DirectionType }>``;

export const CustomTag = styled(Tag)`
  color: var(--cds-tag-color-cool-gray, #121619) !important;
  border-radius: 2px;
  padding: 0;
`;

export const PoweredByCybSafe = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: ${unit(2)};
`;

export const PoweredByText = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: 600;
  margin-right: 5px;
`;
export const CybSafeLogo = styled.img`
  height: 20px;
`;

export const StyledSideNav = styled(SideNav)<{
  expanded: boolean;
  $isAdmin: boolean;
  $withSideMenu: boolean;
  $hasDelegatedAccess: boolean;
}>`
  top: ${({ $hasDelegatedAccess }) =>
    $hasDelegatedAccess
      ? `${HEADER_HEIGHT_VALUE + DELEGATED_ACCESS_HEADER_HEIGHT_VALUE}px`
      : HEADER_HEIGHT};
  border-right: 1px solid var(--cds-layer-accent-01);

  ${({ $isAdmin, $withSideMenu }) => {
    if (!$isAdmin || !$withSideMenu) {
      return `@media only screen and (min-width: 1024px) {
          display: none;
        }`;
    }
  }}

  ${({ expanded }) => {
    if (!expanded) {
      return `@media only screen and (max-width: 1024px) {
          display: none;
        }`;
    }
  }}

  & .cds--side-nav__icon > svg {
    color: var(--cds-icon-secondary, #525252);
  }
`;

export const BottomSection = styled.div`
  border-top-width: 1px;
  border-color: var(--cds-layer-accent-01);
  border-style: solid;
  padding-top: 0.5rem;
`;
