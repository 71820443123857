import Cookies from 'universal-cookie';

type TokenType = {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
};

type CookieOptionsType = {
  path: string;
  maxAge: number;
  secure: boolean;
  sameSite: boolean | 'lax' | 'none' | 'strict' | undefined;
};

export const setCookie = (tokens: TokenType) => {
  const cookies = new Cookies();
  const options: CookieOptionsType = {
    path: '/',
    maxAge: tokens.expiresIn || 60 * 60 * 2,
    secure: window.location.protocol === 'https:',
    sameSite: 'lax',
  };
  cookies.set('accessToken', tokens.accessToken || '', options);
  cookies.set('refreshToken', tokens.refreshToken || '', options);
};

export const getCookie = (cookie = 'accessToken') => {
  const cookies = new Cookies();
  return cookies.get(cookie);
};

export const removeCookie = (cookie = 'accessToken') => {
  const cookies = new Cookies();
  return cookies.remove(cookie);
};

export const removeAllCookies = () => {
  removeCookie('accessToken');
  removeCookie('cs_se');
  removeCookie('sessionid');
  removeCookie('djsso');
  removeCookie('refreshToken');
};
