import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

interface SrollToTopProps {
  children?: React.ReactNode;
}

const ScrollToTop = ({ children }: SrollToTopProps) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
