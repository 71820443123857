import React from 'react';

import { SymposiumLevels } from 'store/features/mainSliceTypes';

export type SubscriptionContextType = {
  hasNoSubscription?: boolean;
  level?: SymposiumLevels;
};
export const SubscriptionContext = React.createContext<
  SubscriptionContextType | undefined
>(undefined);

type SubscriptionContextProviderProps = {
  children: React.ReactNode;
} & SubscriptionContextType;
export const SubscriptionContextProvider = (
  props: SubscriptionContextProviderProps,
) => {
  const { children, ...rest } = props;
  return (
    <SubscriptionContext.Provider value={rest}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  const context = React.useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error(
      'useSubscriptionContext must be used within a SubscriptionContextProvider',
    );
  }
  return context;
};
