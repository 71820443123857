import React from 'react';

import { useAppSelector } from 'store/createStore';

import FeatureFlagsContext from './FeatureFlagsContext';

function FeatureFlagsProvider({ children }) {
  const featureFlags = useAppSelector((state) => state.main.featureFlags);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export default FeatureFlagsProvider;
