import React, { useMemo } from 'react';

import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { number, object, string } from 'yup';

import { useApiData, useApiPost } from 'api';
import Button from 'components/Button';
import { FlexBox } from 'components/Containers';
import { FallbackErrorComponent } from 'components/Error';
import { Dropdown } from 'components/Form/Inputs/Dropdowns';
import Label from 'components/Form/Inputs/Label/Label';
import Textarea from 'components/Form/Inputs/TextArea/TextArea';
import InlineNotification from 'components/Notifications/InlineNotification';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';

import Translation from '../Content/Translation/Translation';

import { ButtonsContainer, StyledContainer } from './PlatformFeedback.styles';
import { usePlatformFeedbackContext } from './PlatformFeedbackContext';

export type PlatformFeedbackProps = {
  setShowFeedbackForm: () => void;
};

type FeedbackQuestions = {
  count: number;
  next: boolean | null;
  previous: boolean | null;
  results: {
    id: number;
    question: string;
    questionType: string;
    description: string;
    feedbackQuestionType: 'platform_feedback' | 'translations';
  }[];
};

const PlatformFeedback = ({ setShowFeedbackForm }: PlatformFeedbackProps) => {
  const intl = useIntl();
  const { blockIds } = usePlatformFeedbackContext();
  const [
    postPlatformFeedback,
    {
      loading: platformFeedbackLoading,
      error: platformFeedbackError,
      data: platformFeedbackSuccess,
    },
  ] = useApiPost(`/api/v1/platform-feedback-answer/r1/`);

  const {
    data: feedbackQuestionData,
    loading: feedbackQuestionsLoading,
    error: feedbackQuestionsError,
  } = useApiData<FeedbackQuestions>('/api/v1/platform-feedback/r1/');

  const feedbackQuestions = useMemo(() => {
    if (feedbackQuestionData?.results) {
      return feedbackQuestionData.results.map((item) => ({
        id: item.id,
        label: item.question,
      }));
    }
    return [];
  }, [feedbackQuestionData]);

  if (feedbackQuestionsLoading) return <LoaderBlock />;
  if (feedbackQuestionsError)
    return <FallbackErrorComponent error={feedbackQuestionsError} />;

  return (
    <>
      {(platformFeedbackSuccess || platformFeedbackError) && (
        <InlineNotification
          type={platformFeedbackError ? 'error' : 'success'}
          titleLabel={intl.formatMessage({
            id: platformFeedbackError ? 'common.error' : 'common.success',
          })}
        />
      )}
      <StyledContainer id="platform-feedback">
        <FlexBox flexDirection="column" w="70%">
          <Formik
            enableReinitialize
            initialValues={{
              feedback_question: '',
              feedback_answer: '',
              page_url: window.location.href,
            }}
            validationSchema={object().shape({
              feedback_answer: string().required(
                intl.formatMessage({ id: 'common.formError.fieldRequired' }),
              ),
              feedback_question: number().required(
                intl.formatMessage({ id: 'common.formError.fieldRequired' }),
              ),
            })}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (
              { feedback_answer, feedback_question, page_url },
              actions,
            ) => {
              await postPlatformFeedback({
                feedbackResponse: {
                  feedback_answer,
                  feedback_question,
                  page_url,
                  ...(blockIds?.length > 0 && { block_ids: blockIds }),
                },
              });
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({ setFieldValue, errors, values, submitForm, isSubmitting }) => (
              <>
                <Dropdown
                  label={
                    <Translation id="footer.reportProblemWithPage.form.dropDown.label" />
                  }
                  items={feedbackQuestions}
                  id="feedback_question"
                  selectedItem={feedbackQuestions.find(
                    (item) => item.id === parseInt(values.feedback_question),
                  )}
                  onChange={({ selectedItem }) => {
                    setFieldValue('feedback_question', selectedItem?.id);
                  }}
                  invalid={!!errors.feedback_question}
                  invalidText={errors.feedback_question}
                />
                <Label label="footer.reportProblemWithPage.form.textField" />
                <Textarea
                  id="feedback_answer"
                  onChangeHandler={(value) => {
                    setFieldValue('feedback_answer', value);
                  }}
                  invalid={!!errors.feedback_answer}
                  invalidLabel={errors.feedback_answer}
                  value={values.feedback_answer}
                />
                <ButtonsContainer>
                  <Button
                    buttonType="secondary"
                    label="common.cancel"
                    onClick={() => setShowFeedbackForm()}
                    disabled={isSubmitting || platformFeedbackLoading}
                  />
                  <Button
                    id="submit-feedback"
                    label="common.submit"
                    onClick={() => submitForm()}
                    loading={isSubmitting || platformFeedbackLoading}
                    disabled={isSubmitting || platformFeedbackLoading}
                  />
                </ButtonsContainer>
              </>
            )}
          </Formik>
        </FlexBox>
      </StyledContainer>
    </>
  );
};

export default PlatformFeedback;
