import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { darken, lighten } from 'polished';
import { persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storageSession from 'redux-persist/lib/storage/session';

import darkThemeConfig, {
  getDarkComponentColors,
} from 'theme/customThemes/darkTheme';
import lightThemeConfig, {
  getLightComponentColors,
} from 'theme/customThemes/defaultTheme';

export interface ThemeStateInterface {
  type: 'light' | 'dark';
  lightTheme: typeof lightThemeConfig;
  darkTheme: typeof darkThemeConfig;
  tempLightTheme?: typeof lightThemeConfig;
  tempDarkTheme?: typeof darkThemeConfig;
  logo?: string;
  darkLogo?: string;
  primaryColor?: string;
}

const initialState: ThemeStateInterface = {
  type: 'light',
  lightTheme: lightThemeConfig,
  darkTheme: darkThemeConfig,
  tempLightTheme: undefined,
  tempDarkTheme: undefined,
  logo: undefined,
  darkLogo: undefined,
  primaryColor: undefined,
};

type ChangeThemeValuesPayload = {
  primaryColor: string;
};

function updateThemeColors(
  state: ThemeStateInterface,
  primaryColor: string,
  isDark: boolean,
) {
  const theme = isDark ? state.tempDarkTheme : state.tempLightTheme;

  const calculatedPrimaryColor = {
    100: isDark ? lighten(0.55, primaryColor) : lighten(0.5, primaryColor),
    200: isDark ? lighten(0.65, primaryColor) : lighten(0.6, primaryColor),
    300: primaryColor,
    400: darken(0.07, primaryColor),
    500: darken(0.08, primaryColor),
  };

  if (theme) {
    theme.colorPalette.primary = calculatedPrimaryColor;
    theme.componentColors = isDark
      ? getDarkComponentColors(theme.colorPalette)
      : getLightComponentColors(theme.colorPalette);
  }
}

function createExtraReducers() {
  return (builder) => {
    builder.addCase(`main/setBootstrapData`, (state, action) => {
      state.primaryColor =
        action.payload?.themeSettings?.primaryColor ?? undefined;
      if (
        action.payload.themeSettings.logo &&
        action.payload.themeSettings.darkLogo !== undefined
      ) {
        state.logo = action.payload.themeSettings.logo;
      }
      if (action.payload.themeSettings.darkLogo) {
        state.darkLogo = action.payload.themeSettings.darkLogo;
      }
      if (action.payload?.themeSettings?.primaryColor) {
        state.tempLightTheme = { ...state.lightTheme };
        state.tempDarkTheme = { ...state.darkTheme };
        const primaryColor = action.payload.themeSettings.primaryColor;

        updateThemeColors(state, primaryColor, false);
        updateThemeColors(state, primaryColor, true);
      }
    });
  };
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.type = action.payload;
    },
    changeThemesValues: (
      state,
      action: PayloadAction<ChangeThemeValuesPayload>,
    ) => {
      state.tempLightTheme = { ...state.lightTheme };
      state.tempDarkTheme = { ...state.darkTheme };
      const { primaryColor } = action.payload;
      state.primaryColor = primaryColor;

      updateThemeColors(state, primaryColor, false);
      updateThemeColors(state, primaryColor, true);
    },
    revertThemesValues: (state) => {
      state.tempDarkTheme = undefined;
      state.tempLightTheme = undefined;
      state.primaryColor = undefined;
    },
  },
  extraReducers: createExtraReducers(),
});

/* Actions */
export const { setTheme, changeThemesValues, revertThemesValues } =
  themeSlice.actions;

/* Selectors */
export const selectThemeState = (state) => state.theme;

export const selectThemeType = createSelector(
  [selectThemeState],
  (theme) => theme.type,
);

export const selectLogo = createSelector([selectThemeState], (theme) => {
  switch (theme.type) {
    case 'dark':
      return theme.darkLogo;
    case 'light':
    default:
      return theme.logo;
  }
});

export const selectTheme = createSelector([selectThemeState], (theme) => {
  switch (theme.type) {
    case 'dark':
      return theme.tempDarkTheme || theme.darkTheme;
    case 'light':
    default:
      return theme.tempLightTheme || theme.lightTheme;
  }
});
/* Persist */
const themeConfig = {
  key: 'theme',
  storage: storageSession,
  blacklist: [
    'lightTheme',
    'darkTheme',
    'tempLightTheme',
    'tempDarkTheme',
    'logo',
    'darkLogo',
  ],
  stateReconciler: autoMergeLevel1,
};

export default persistReducer(themeConfig, themeSlice.reducer);
