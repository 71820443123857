// Define the state type
export type State = {
  originRoute: string | null;
};

// Define the action types
type UpdateAction = {
  type: typeof ACTIONS.UPDATE;
  payload: string;
};

type ClearAction = {
  type: typeof ACTIONS.CLEAR;
};

export type Action = UpdateAction | ClearAction;

// Define the initial state
export const initialState: State = { originRoute: null };

// Define the actions
export const ACTIONS = {
  UPDATE: 'UPDATE',
  CLEAR: 'CLEAR',
} as const;

// Define the reducer
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return { originRoute: action.payload };
    case ACTIONS.CLEAR:
      return { originRoute: null };
    default:
      return state; // Returning the current state in case of an unknown action
  }
};
