import { styled } from 'styled-components';

import { FILTERS_WIDTH } from '../../Filters/PageFilters/FiltersSidePanel/FiltersSidePanel.styles';

const INTERCOM_DEFAULT_POSITION = 20;

/* Intercoms default z-index value */
const Z_INDEX = 2147483000;

export const IntercomLauncher = styled.div`
  position: fixed;
  bottom: 20px;
  right: ${INTERCOM_DEFAULT_POSITION}px;
  width: 48px;
  height: 48px;
  z-index: ${Z_INDEX};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPalette.secondary[500]};
  cursor: pointer;
  &:hover {
    transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
    transform: scale(1.1);
  }
  /* Styling for when the filters side panel is open */
  &.filters-open {
    right: ${FILTERS_WIDTH + INTERCOM_DEFAULT_POSITION}px;
    transition: right 0.1s ease-in-out;
  }
`;

export const NotificationContainer = styled.div`
  z-index: ${Z_INDEX + 1};
  position: absolute;
  bottom: 30px;
  left: 40px;
  min-width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colorPalette.core.white};
`;

export const NotificationNumber = styled.span`
  background: #ff4c4c; // Intercoms notification color
  display: flex;
  justify-content: center;
  font-size: 11px;
  color: ${({ theme }) => theme.colorPalette.core.white};
  font-weight: 700;
  border-radius: 9px;
  width: fit-content;
  min-width: 16px;
  height: 16px;
  box-sizing: border-box;
  line-height: 16px;
  padding: 0px 4px;
`;

export const IconContainer = styled.div<{
  $display: boolean;
  $iconType: 'close' | 'open';
}>`
  display: flex;
  justify-content: center;
  position: relative;
  top: ${({ $iconType }) => ($iconType === 'open' ? '-12px' : '12px')};
  transition:
    transform 0.16s linear,
    opacity 0.08s linear;
  opacity: ${({ $display }) => ($display ? 1 : 0)};
  ${({ $display, $iconType }) =>
    $iconType === 'open'
      ? `
        transform: ${$display ? 'rotate(0deg) scale(1)' : 'rotate(30deg) scale(0)'}
      `
      : `
         transform :${$display ? 'rotate(0deg) scale(1)' : 'rotate(-60deg) scale(1)'}
      `}
`;
