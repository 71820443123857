import React from 'react';

import { Loading } from '@carbon/react';

const Spinner = ({ small }: { small?: boolean }) => {
  return (
    <div aria-busy="true" data-testid="spinner-wrapper">
      <Loading
        description="Active loading indicator"
        withOverlay={false}
        small={small}
      />
    </div>
  );
};

export default Spinner;
