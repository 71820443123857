import styled from 'styled-components';

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0;
  align-items: center;
  padding: 8px 0px;
`;

export const BeamerButton = styled.button`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-left: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colorPalette.grey[800]};
  svg {
    width: 50% !important;
    height: 50% !important;
  }
  &:focus {
    outline: 2px solid ${(props) => props.theme.colorPalette.primary[300]};
    outline-offset: -2px;
  }
`;
