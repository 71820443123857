import React, { ReactNode, createContext, useContext, useState } from 'react';

import ToastContainer from './ToastContainer';

export interface Toast {
  id: number;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

interface ToastContextProps {
  addToast: (message: string, type?: Toast['type']) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = React.useCallback(
    (message: string, type?: Toast['type']) => {
      const id = Date.now();
      // default type to success if not provided
      if (!type) {
        type = 'success';
      }
      const newToast = { id, message, type };

      setToasts((prevToasts) => [...prevToasts, newToast]);
    },
    [],
  );

  const removeToast = (id: number) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id),
    );
  };

  const value = React.useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
