import styled from 'styled-components';

import { browser } from 'theme';

export const CenteredSpacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  min-height: 250px;
  margin: 0 auto;
  ${browser && browser.name === 'ie' && browser.version.substring(0, 2) === '11'
    ? 'margin: auto auto; width: 100%'
    : ''}
`;
