import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import shallowequal from 'shallowequal';

import ApiDataContext from './ApiDataContext';

type Options = {
  reduxAction?: (payload: unknown) => AnyAction;
  cache?: boolean;
  dontAppendClientId?: boolean;
  dontAppendLocale?: boolean;
  dontAppendSlash?: boolean;
};

type StateModel<T> = {
  data: T | null;
  error: any;
  loading: boolean;
};

type Action<T> =
  | { type: 'REQUEST_START' }
  | { type: 'REQUEST_SUCCESS'; data: T }
  | { type: 'REQUEST_ERROR'; error: any };

export default function useApiMutation<T = any>(
  method: string,
  endpoint: string,
  _options: Options = {
    reduxAction: undefined,
  },
) {
  const INITIAL_STATE: StateModel<T> = {
    data: null,
    error: null,
    loading: false,
  };
  const reducer = (state: StateModel<T>, action: Action<T>): StateModel<T> => {
    switch (action.type) {
      case 'REQUEST_START': {
        return { ...INITIAL_STATE, data: state.data, loading: true };
      }

      case 'REQUEST_SUCCESS': {
        return {
          data: action.data,
          error: null,
          loading: false,
        };
      }

      case 'REQUEST_ERROR': {
        return {
          data: null,
          error: action.error,
          loading: false,
        };
      }

      default:
        throw new Error('Unhandled action type in useApiMutation reducer.');
    }
  };
  // Because the options object is created inside a render method it will
  // change on every render. We need to wrap it in state and then only change it
  // if the values inside the options object actually changes.
  const [options, setOptions] = useState(_options);
  useEffect(() => {
    if (!shallowequal(options, _options)) {
      setOptions(_options);
    }
  }, [options, _options]);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const apiData = useContext(ApiDataContext);
  const applyMutation = useCallback(
    (params?: Record<string, unknown>) => {
      dispatch({
        type: 'REQUEST_START',
      });
      if (!apiData) {
        const error = new Error('ApiDataContext is null');
        dispatch({
          type: 'REQUEST_ERROR',
          error,
        });
        throw error;
      }

      return apiData
        .fetch(method, endpoint, params, options)
        .then((data) => {
          dispatch({
            type: 'REQUEST_SUCCESS',
            data,
          });
          return data as T;
        })
        .catch((error) => {
          dispatch({
            type: 'REQUEST_ERROR',
            error,
          });
          throw error;
        });
    },
    [method, endpoint, apiData, options],
  );
  return [applyMutation, state] as [typeof applyMutation, typeof state];
} // Create partial helper functions

export function useApiGet<T = any>(endpoint: string, options?: Options) {
  return useApiMutation<T>('GET', endpoint, options);
}

export const useApiPost = useApiMutation.bind(null, 'POST');
export const useApiDelete = useApiMutation.bind(null, 'DELETE');
export const useApiPut = useApiMutation.bind(null, 'PUT');
export const useApiPatch = useApiMutation.bind(null, 'PATCH');
