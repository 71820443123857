import { DefaultTheme } from 'styled-components';

export const updateCarbonThemeProperties = (
  theme: DefaultTheme,
  themePrimaryColor: string | undefined,
) => {
  if (themePrimaryColor) {
    Object.keys(theme.colorPalette.primary).forEach((key) => {
      document.documentElement.style.setProperty(
        `--cs-brand-primary-${key}`,
        theme.colorPalette.primary[key],
      );
    });
    document.documentElement.style.setProperty(
      '--cs-brand-primary',
      theme.colorPalette.primary[300],
    );
  } else {
    // remove the styles
    Object.keys(theme.colorPalette.primary).forEach((key) => {
      document.documentElement.style.removeProperty(
        `--cs-brand-primary-${key}`,
      );
    });
    document.documentElement.style.removeProperty('--cs-brand-primary');
  }
};
