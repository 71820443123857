import React from 'react';

import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useIntl } from 'react-intl';

import { CircularButton, WidgetIcon } from '../../Widgets.styles';

interface InsightsButtonInterface {
  toggleOpen: (open: boolean) => void;
  open: boolean;
}

const InsightsButton = ({ toggleOpen, open }: InsightsButtonInterface) => {
  const intl = useIntl();
  return (
    <CircularButton
      aria-label={intl.formatMessage({
        id: 'component.widgets.insights.button.ariaLabel',
      })}
      onClick={() => toggleOpen(!open)}
      data-testid="insights-button"
    >
      {open ? (
        <WidgetIcon $close key="close" icon={faTimes} />
      ) : (
        <WidgetIcon flip="horizontal" key="open" icon={faCommentAlt} />
      )}
    </CircularButton>
  );
};

export default InsightsButton;
