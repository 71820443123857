import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

body {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  background-color: ${({ theme }) => theme.componentColors.bodyBg};
  &.report {
    background-color: #f5faf8;
  }
  color: ${({ theme }) => theme.componentColors.bodyTextColor};
}

#react-root {
  height: 100%;
}

.svg-black-text {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.875rem;
  fill: #000;
}

br {
  content: ' ';
  height: 24px;
  display: block;
}

i {
  font-style: italic;
}

a {
  color: ${({ theme }) => theme.componentColors.bodyTextLinkColor};
}

b,
strong {
  font-weight: 600;
}
`;

export default GlobalStyle;
