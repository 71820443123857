import React from 'react';

import { Link } from 'react-router-dom';

import { Text } from 'components/Text';
import { UserInitials } from 'components/Users';
import { useFeatureFlag } from 'feature-flags';
import { useAppSelector } from 'store/createStore';
import {
  selectClientInfo,
  selectClientName,
  selectUserInfo,
} from 'store/features/mainSlice';

import {
  CompanyName,
  InfoContainer,
  ParentContainer,
} from './LoggedInUser.styles';

const LoggedInUser = () => {
  const useSSOProfilePicture = useFeatureFlag('use-sso-profile-picture');
  const { id, publicId, firstName, lastName, client, profilePictureUrl } =
    useAppSelector(selectUserInfo);
  const clientName = useAppSelector(selectClientName);
  const { isUserProgressOn } = useAppSelector(selectClientInfo);
  const overrideClient = useAppSelector(
    (state) => state.main.delegatedAccessClientId,
  );

  const isDelegatedAccessFlagEnabled = useFeatureFlag(
    'use-delegated-access-header',
  );
  const delegated =
    overrideClient &&
    overrideClient !== client &&
    !isDelegatedAccessFlagEnabled;

  if (firstName && lastName && clientName) {
    return (
      <ParentContainer
        $delegated={!!delegated}
        as={isUserProgressOn ? Link : 'div'}
        to={isUserProgressOn ? `/reports/user/${publicId}/${id}` : ''}
      >
        <InfoContainer>
          <Text bold variant="bodySmall">{`${firstName} ${lastName}`}</Text>
          <CompanyName id="client-name">
            <span>{clientName}</span>
          </CompanyName>
        </InfoContainer>
        <UserInitials
          firstName={firstName}
          lastName={lastName}
          userProfileImage={useSSOProfilePicture ? profilePictureUrl : null}
        />
      </ParentContainer>
    );
  }

  return null;
};

export default LoggedInUser;
