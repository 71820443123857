import React, { useState } from 'react';

import Translation from 'components/Content/Translation/Translation';

import Button from '../../../Button';

import { HelperText, LabelText, RootLabelContainer } from './Label.styles';

export type LabelProps = {
  id?: string;
  label: string;
  helperLabel?: string | React.ReactElement;
  optional?: boolean;
  children?: React.ReactNode;
  inline?: boolean;
  displayChildrenToggle?: boolean;
};

const Label = ({
  id,
  label,
  helperLabel,
  optional,
  inline,
  displayChildrenToggle,
  children,
}: LabelProps) => {
  const [displayChildren, setDisplayChildren] = useState(
    !displayChildrenToggle,
  );
  return (
    <RootLabelContainer $inline={inline}>
      <div className="labelcontainer" id={`${id ?? ''}_label`}>
        {label !== '' && (
          <LabelText htmlFor={id}>
            <span>
              <Translation id={label} />
              {optional && (
                <>
                  {` `}
                  <Translation id="label_optional" />
                </>
              )}
            </span>
            {displayChildrenToggle && (
              <Button
                data-testid="toggle-display-children-button"
                buttonType="ghost"
                label={
                  displayChildren
                    ? 'component.accordion.collapse'
                    : 'component.accordion.expand'
                }
                onClick={() => {
                  setDisplayChildren(!displayChildren);
                }}
              />
            )}
          </LabelText>
        )}
        {helperLabel && (
          <HelperText>
            {typeof helperLabel === 'string' ? (
              <Translation id={helperLabel} />
            ) : (
              helperLabel
            )}
          </HelperText>
        )}
      </div>
      {displayChildren && children}
    </RootLabelContainer>
  );
};

export default Label;
