import React from 'react';

import { SearchProps } from '@carbon/react';
import { useIntl } from 'react-intl';

import { InputWrapper } from '../Inputs.styles';

import { Input } from './Search.styles';

export interface SearchInputProps extends Omit<SearchProps, 'labelText'> {
  /**
   * ID of Search input.
   */
  id?: string;
  /**
   * The label of the input.
   */
  label: string;
  /**
   * Function called when value changes.
   */
  onChangeHandler: (arg0: string) => void;
  /**
   * Placeholder value for the input.
   */
  placeholder?: string;
  /**
   * CSS Classes to extend styling.
   */
  className?: string;
  /**
   * Value of the input.
   */
  value?: string;
  /**
   * Value to disable the input.
   */
  disabled?: boolean;
}

export const SearchInput = ({
  id = 'search-input',
  label,
  onChangeHandler,
  placeholder,
  className,
  value,
  disabled,
  size = 'lg',
}: SearchInputProps) => {
  const intl = useIntl();
  return (
    <InputWrapper>
      <Input
        id={id}
        className={className}
        labelText={label}
        placeholder={placeholder}
        value={value}
        closeButtonLabelText={intl.formatMessage({ id: 'label_clearSearch' })}
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        disabled={disabled}
        size={size}
      />
    </InputWrapper>
  );
};

export default SearchInput;
