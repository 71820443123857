import React, { useState } from 'react';

import Translation from 'components/Content/Translation/Translation';
import { withErrorBoundary } from 'components/Error';
import { Modal } from 'components/Modal';
import { useAppDispatch } from 'store/createStore';
import { setDelegateAccessClientId } from 'store/features/mainSlice';

import OrganisationChooserContent from './components/OrganisationChooserContent';

const OrganisationChooser = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  const handleSubmit = async () => {
    await dispatch(setDelegateAccessClientId(selectedOrgId));
    window.location.reload();
  };

  return (
    <Modal
      open={open}
      modalHeading={<Translation id="label_choose_organisation" />}
      primaryButtonText={<Translation id="label_choose_organisation" />}
      primaryButtonDisabled={!selectedOrgId}
      onRequestSubmit={handleSubmit}
      secondaryButtonText={<Translation id="common.cancel" />}
      onSecondarySubmit={onClose}
      onRequestClose={onClose}
    >
      <OrganisationChooserContent setSelectedOrgId={setSelectedOrgId} />
    </Modal>
  );
};

export default withErrorBoundary(OrganisationChooser);
