import React, { useEffect, useMemo, useState } from 'react';

import ReactDOM from 'react-dom';
import { useLocation } from 'react-router';
import { useIntercom } from 'react-use-intercom';

import { useAppSelector } from 'store/createStore';
import { selectBottomBarHeight } from 'store/features/configSlice';
import {
  selectIntercomAppId,
  selectIntercomInfo,
} from 'store/features/mainSlice';

import IntercomButton from './IntercomButton';

export const INTERCOM_SELECTOR_ID = 'intercom-widget-container';

export const getBaseIntercomSettings = (
  intercomAppId: string,
  bottomBarHeight: number,
) => ({
  appId: intercomAppId,
  verticalPadding: bottomBarHeight + 80,
  customLauncherSelector: `#${INTERCOM_SELECTOR_ID}`,
  hideDefaultLauncher: true,
  alignment: 'right',
});

const Intercom = () => {
  const location = useLocation();
  const bottomBarHeight = useAppSelector(selectBottomBarHeight);
  const intercomAppId = useAppSelector(selectIntercomAppId);
  const intercomInfo = useAppSelector(selectIntercomInfo);
  const { boot, update, shutdown } = useIntercom();
  const [isInitialized, setIsInitialized] = useState(false);

  const isLoginPage = useMemo(
    () => location.pathname === '/login',
    [location.pathname],
  );

  const intercomSettings = useMemo(
    () => ({
      ...getBaseIntercomSettings(intercomAppId, bottomBarHeight),
      ...(!isLoginPage && {
        ...intercomInfo,
      }),
    }),
    [bottomBarHeight, intercomAppId, intercomInfo, isLoginPage],
  );

  useEffect(() => {
    /* Wait for Intercom to be attached to the window */
    const intercomInterval = setTimeout(() => {
      if (!!window.Intercom) {
        boot(intercomSettings);
        setIsInitialized(true);
      }
    }, 500);

    return () => {
      clearTimeout(intercomInterval);
      shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialized) {
      update(intercomSettings);
    }
  }, [isInitialized, intercomSettings, update]);

  return isInitialized
    ? ReactDOM.createPortal(<IntercomButton />, document.body)
    : null;
};

export default Intercom;
