import React, { useState } from 'react';

import { CheckmarkFilled, Share } from '@carbon/icons-react';
import { SkipToContent } from '@carbon/react';
import { useIntl } from 'react-intl';

import Button from 'components/Button';
import Translation from 'components/Content/Translation/Translation';
import { useAppSelector } from 'store/createStore';
import { selectIsCybSafeAdmin } from 'store/features/mainSlice';

import {
  DelegatedAccessHeaderWrapper,
  ResetTextContainer,
  TextsContainer,
} from './DelegatedAccessHeader.styles';

type DelegatedAccessHeaderProps = {
  name: string;
  onResetDelegatedAccess: () => void;
};
const DelegatedAccessHeader = ({
  name,
  onResetDelegatedAccess,
}: DelegatedAccessHeaderProps) => {
  const intl = useIntl();
  const isCybSafeAdmin = useAppSelector(selectIsCybSafeAdmin);
  const { delegatedAccessClientId } = useAppSelector((state) => state.main);
  const urlObj = new URL(window.location.href);

  if (!urlObj.searchParams.has('delegated_access_client_id')) {
    urlObj.searchParams.append(
      'delegated_access_client_id',
      delegatedAccessClientId ? delegatedAccessClientId.toString() : '',
    );
  }
  const copyUrl = urlObj.toString();

  const [copyCompletion, setCopyCompletion] = useState<
    'success' | 'error' | null
  >(null);
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copyUrl);
      setCopyCompletion('success');
      setTimeout(() => {
        setCopyCompletion(null);
      }, 3000);
    } catch (e) {
      setCopyCompletion('error');
      setTimeout(() => {
        setCopyCompletion(null);
      }, 3000);
    }
  };

  return (
    <DelegatedAccessHeaderWrapper data-testid="delegated-access-header">
      <SkipToContent>
        {intl.formatMessage({ id: 'header.skipToContent' })}
      </SkipToContent>
      <TextsContainer>
        <Translation id="page.settings.orgManagement.delegatedAccess.notification.title" />
        <Translation
          id="page.settings.orgManagement.delegatedAccess.notification.subtitle"
          values={{ clientName: name }}
        />
        {isCybSafeAdmin && (
          <Button
            buttonType="primary"
            data-testid="sharing-btn"
            label={
              copyCompletion
                ? 'component.link.button.copy.success'
                : 'component.link.button.shareLink'
            }
            renderIcon={copyCompletion ? CheckmarkFilled : Share}
            onClick={() => copyToClipboard()}
          />
        )}
      </TextsContainer>
      <ResetTextContainer
        data-testid="reset-delegated-access"
        onClick={onResetDelegatedAccess}
      >
        <Translation id="menu.userMenu.resetDelegatedAccess" />
      </ResetTextContainer>
    </DelegatedAccessHeaderWrapper>
  );
};

export default DelegatedAccessHeader;
