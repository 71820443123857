import React, { useCallback, useEffect, useState } from 'react';

import { ChevronDown } from '@carbon/icons-react';
import { useIntercom } from 'react-use-intercom';

import { IntercomWidget } from '../../CustomIcons';

import { INTERCOM_SELECTOR_ID } from './Intercom';
import {
  IconContainer,
  IntercomLauncher,
  NotificationContainer,
  NotificationNumber,
} from './Intercom.styles';

const IntercomButton = () => {
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const { isOpen } = useIntercom();

  const getNotificationCount = useCallback(() => {
    /* Handle notification count */
    if (window.Intercom) {
      window.Intercom('onUnreadCountChange', (unreadCount: number) => {
        setNotificationCount(unreadCount);
      });
    }
  }, []);

  useEffect(() => {
    getNotificationCount();
  }, [getNotificationCount]);

  return (
    <IntercomLauncher
      data-testid={INTERCOM_SELECTOR_ID}
      id={INTERCOM_SELECTOR_ID}
    >
      {notificationCount > 0 && (
        <NotificationContainer data-testid="intercom-notification">
          <NotificationNumber>{notificationCount}</NotificationNumber>
        </NotificationContainer>
      )}
      <IconContainer
        data-testid="close-intercom-icon"
        $display={isOpen}
        $iconType="close"
      >
        <ChevronDown size={24} fill="white" />
      </IconContainer>
      <IconContainer
        data-testid="open-intercom-icon"
        $display={!isOpen}
        $iconType="open"
      >
        <IntercomWidget size={24} fill="white" />
      </IconContainer>
    </IntercomLauncher>
  );
};

export default IntercomButton;
