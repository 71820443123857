import React, { ReactNode, useMemo } from 'react';

import { useStore } from 'react-redux';

import ApiData from './ApiData';
import ApiDataContext from './ApiDataContext';

const ApiDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const store = useStore();
  const apiData = useMemo(() => new ApiData(store), [store]);
  return (
    <ApiDataContext.Provider value={apiData}>
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;
