import styled, { css } from 'styled-components';

import { breakpoint, unit } from 'theme';

export const LabelText = styled.label`
  display: flex;
  align-items: baseline;
  gap: ${unit(3)};
  font-weight: 600;
  font-size: 1rem;
`;

export const HelperText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.s} !important;
  line-height: 1rem !important;
  color: ${({ theme }) => theme.componentColors.bodyTextColor};
`;

export const RootLabelContainer = styled.div<{ $inline?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${unit(1)};
  word-break: break-word;

  > :first-child {
    width: 200px;
    ${breakpoint('tablet')} {
      width: 100%;
    }
  }

  .labelcontainer {
    display: flex;
    flex-direction: column;
    gap: ${unit(1)};

    p {
      margin-bottom: 0;
    }
  }

  ${({ $inline }) =>
    $inline &&
    css`
      flex-direction: column;
      gap: ${unit(0.5)};

      & > :first-child {
        width: auto;
      }

      .labelcontainer {
        display: flex;
        flex-direction: column;
        gap: ${unit(0.5)};
        flex: 1 1 auto;
      }
    `}

  > :last-child {
    flex: 1;
  }
`;
