type Response = {
  fetchTime: number;
  payload: any;
};

const fiveMinutes = 5 * 60 * 1000;

/**
 * A cache for storing request responses, featuring:
 * - `get` with TTL
 * - cache size limiting, with least-recently *updated* entries purged first
 *
 * Mostly taken from Relay's RelayQueryResponseCache: https://github.com/facebook/relay/blob/5e447eb6e748543987dfa6bf4eed39bbc6765e3f/packages/relay-runtime/network/RelayQueryResponseCache.js
 */
export default class ApiRequestCache {
  _responses: Map<string, Response>;
  _size: number;
  _ttl: number;

  constructor(
    { size, ttl }: { size: number; ttl: number } = {
      size: 250,
      ttl: fiveMinutes,
    },
  ) {
    if (size < 1) {
      throw new Error(
        `ApiRequestCache: Expected the max cache size to be > 0, got \`${size}\``,
      );
    }
    if (ttl < 1) {
      throw new Error(
        `ApiRequestCache: Expected the max ttl size to be > 0, got \`${ttl}\``,
      );
    }

    this._responses = new Map();
    this._size = size;
    this._ttl = ttl;
  }

  clear(): void {
    this._responses.clear();
  }

  get(cacheKey: string): Response | null {
    this._responses.forEach((response, key) => {
      if (!isCurrent(response.fetchTime, this._ttl)) {
        this._responses.delete(key);
      }
    });
    const response = this._responses.get(cacheKey);
    return response ? response.payload : null;
  }

  set(cacheKey: string, payload: any): void {
    const fetchTime = Date.now();
    this._responses.delete(cacheKey); // deletion resets key ordering
    this._responses.set(cacheKey, {
      fetchTime,
      payload,
    });
    // Purge least-recently updated key when max size reached
    if (this._responses.size > this._size) {
      const firstKey = this._responses.keys().next();
      if (!firstKey.done) {
        this._responses.delete(firstKey.value);
      }
    }
  }
}

/**
 * Determine whether a response fetched at `fetchTime` is still valid given
 * some `ttl`.
 */
function isCurrent(fetchTime: number, ttl: number): boolean {
  return fetchTime + ttl >= Date.now();
}
