import React from 'react';

import { useApiData } from 'api';
import Translation from 'components/Content/Translation/Translation';
import { RadioTile, TileGroup } from 'components/Tile';
import { InsightsStateInterface } from 'components/Widgets/Insights/Insights.types';

import { QuestionLoadingSpinner } from './Insights.styles';

interface QuestionSectionInterface {
  setInsight: (
    state: (state: InsightsStateInterface) => InsightsStateInterface,
  ) => void;
}

const QuestionSection = ({ setInsight }: QuestionSectionInterface) => {
  const { data, loading } = useApiData('/api/v1/insight-questions/r1/');
  const getQuestionTiles = (): JSX.Element[] => {
    let tiles: JSX.Element[] = [];
    if (loading) {
      for (let i = 0; i < 3; i++) {
        tiles.push(
          <RadioTile
            key={`loading-tile-${i}`}
            value={i}
            data-testid="loading-spinner"
          >
            <QuestionLoadingSpinner size={2} />
          </RadioTile>,
        );
      }
    } else if (data && data.results.length > 0) {
      tiles = data.results.map((d) => (
        <RadioTile key={d.id} value={d.id}>
          <span data-testid="insight-question">{d.insightQuestion}</span>
        </RadioTile>
      ));
    } else {
      tiles.push(
        <RadioTile key="feedback-tile" value={1}>
          <Translation id="component.insights.radio.feedback" />
        </RadioTile>,
      );
    }
    return tiles;
  };
  return (
    <TileGroup
      name="insights-feedback"
      legend="insights-feedback-choices"
      onChange={(e) => {
        setInsight((prevState) => ({
          ...prevState,
          type: e.toString(),
        }));
      }}
    >
      {getQuestionTiles()}
    </TileGroup>
  );
};

export default QuestionSection;
