import styled from 'styled-components';

import theme from 'theme';

import { DELEGATED_ACCESS_HEADER_HEIGHT } from '../../Header/Header.styles';

export const DelegatedAccessHeaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  justify-content: space-between;
  height: ${DELEGATED_ACCESS_HEADER_HEIGHT};
  width: 100%;
  background-color: ${({ theme }) => theme.colorPalette.primary[300]};
  z-index: 10;
  padding: 1rem;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  span: first-child {
    font-weight: 600;
  }
  span {
    color: ${({ theme }) => theme.colorPalette.core.white};
  }
`;

export const ResetTextContainer = styled.div`
  span {
    color: ${({ theme }) => theme.colorPalette.core.white};
    text-decoration: underline;
    line-height: ${theme.fontSizes.m};
    &:hover {
      cursor: pointer;
    }
  }
`;
