import { detect } from 'detect-browser';

import { breakpoints } from 'utils/breakpoints';

import defaultTheme from './customThemes/defaultTheme';

export const MAX_CONTAINER_WIDTH = '1200px';
export const MAX_CONTENT_CONTAINER_WIDTH = '1024px'; // Max width for user content
export const SIZE_UNIT = 8;
export const browser = detect();

export const unit = (multiplier: number): string =>
  `${multiplier * SIZE_UNIT}px`;

export const breakpoint = (size: keyof typeof breakpoints | string) => {
  const breakpointWidth =
    breakpoints[size] !== undefined ? `${breakpoints[size]}px` : size;
  return `@media screen and (max-width: ${breakpointWidth})`;
};

export const ie11Only = `@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)`;

export default defaultTheme;
