import * as Sentry from '@sentry/react';
import { Location } from 'react-router';

export { default as AnalyticsWrapper } from './AnalyticsWrapper';

const __DEV__ = process.env.NODE_ENV !== 'production';

const SENSITIVE_PARAMS = ['email', 'password'];

function filterSensitiveParams(data) {
  if (!data) {
    return {};
  }

  return Object.keys(data)
    .filter((key) => !SENSITIVE_PARAMS.includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
}

export function trackEvent(name: string, payload?: unknown): void {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log(`[Track Event] ${name}`, filterSensitiveParams(payload));
  } else {
    if (typeof (window as any).amplitude === `object`) {
      (window as any).amplitude
        .getInstance()
        .logEvent(name, filterSensitiveParams(payload));
    }
  }
}
export function trackSessionStart(location: Location) {
  trackEvent(
    'Platform: Start session',
    location
      ? {
          urlPath: location.pathname,
          urlQueryString: location.search,
          urlHash: location.hash,
        }
      : {},
  );
}
export function trackPageview(location: Location, menuVersion: number) {
  if (typeof sessionStorage !== 'undefined') {
    const storage = window.sessionStorage;

    if (!storage.getItem('hasTrackedSessionStart')) {
      trackSessionStart(location);
      storage.setItem('hasTrackedSessionStart', true as unknown as string);
    }
  }

  trackEvent('Platform: Pageview', {
    urlPath: location.pathname,
    urlQueryString: location.search,
    urlHash: location.hash,
    menuVersion: menuVersion,
  });
}
export function setUserProperties(properties: unknown) {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log(`[Set user properties]`, properties);
  } else {
    if (typeof (window as any).amplitude === `object`) {
      (window as any).amplitude.getInstance().setUserProperties(properties);
    }
  }
}

export function identifyUser(id?: number, client_id?: number) {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log(`[Identify User] ${id}`);
  } else {
    if (typeof (window as any).amplitude === `object`) {
      (window as any).amplitude.getInstance().setUserId(id);
    }
  }

  Sentry.setUser({
    id,
    client_id,
  });
}
