import Button, { ButtonProps, ButtonType } from './Button';
import CopyButton from './SpecialisedButtons/CopyButton';
import LinkButton from './SpecialisedButtons/LinkButton';
import PopoverButton from './SpecialisedButtons/PopoverButton';
import VoteButton from './SpecialisedButtons/VoteButton';

export { CopyButton, LinkButton, PopoverButton, VoteButton };

export type { ButtonProps, ButtonType };
export default Button;
