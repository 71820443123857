import styled from 'styled-components';

import { unit } from 'theme';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${unit(2)};
`;

export const EditLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: ${unit(1)};

  > textarea {
    height: 120px;
  }
`;

export const AcceptChangeLabel = styled.label`
  display: flex;
  gap: ${unit(1)};
`;
