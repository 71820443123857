import { useCallback, useContext, useEffect, useReducer } from 'react';

import { APIError } from 'components/Error';

import { Options } from './ApiData';
import ApiDataContext from './ApiDataContext';

type StateModel<T> =
  | { status: 'loading'; data: null; error: undefined }
  | { status: 'success'; data: T; error: undefined }
  | { status: 'error'; data: null; error: APIError };

// Action types for the reducer
type Action<T> =
  | { type: 'REQUEST_START' }
  | { type: 'REQUEST_SUCCESS'; data: T }
  | { type: 'REQUEST_ERROR'; error: APIError };

// Define the return type of the hook
type UseApiDataReturn<T> =
  | { data: T; loading: false; error: undefined; refetch: () => void } // Successful state
  | { data: null; loading: true; error: undefined; refetch: () => void } // Loading state
  | { data: null; loading: false; error: APIError; refetch: () => void }; // Error state

// TODO: The T = any can be removed when we move to types throughout the useApiData usage
export default function useApiData<T = any>(
  endpoint:
    | (string | null | undefined)
    | ((arg0: { getData: any }) => Promise<T>),
  _options: Options = {
    cache: false,
    reduxAction: undefined,
    dontAppendClientId: false,
    dontAppendLocale: false,
  },
): UseApiDataReturn<T> {
  const INITIAL_STATE: StateModel<T> = {
    status: 'loading',
    data: null,
    error: undefined,
  };

  const reducer = (_state: StateModel<T>, action: Action<T>): StateModel<T> => {
    switch (action.type) {
      case 'REQUEST_START':
        return { status: 'loading', data: null, error: undefined };

      case 'REQUEST_SUCCESS':
        return { status: 'success', data: action.data, error: undefined };

      case 'REQUEST_ERROR':
        return { status: 'error', data: null, error: action.error };

      default:
        throw new Error('Unhandled action type in useApiData reducer.');
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const apiData = useContext(ApiDataContext);
  if (!apiData) {
    throw new Error('ApiDataContext is null');
  }

  const fetchDataWrapper = useCallback(() => {
    if (!endpoint) return;
    dispatch({ type: 'REQUEST_START' }); // ensure the loading state is set, especially for refetch
    let getDataResponse: Promise<T>;

    if (typeof endpoint === 'string') {
      getDataResponse = apiData.getData(endpoint, _options);
    } else if (typeof endpoint === 'function') {
      getDataResponse = endpoint({ getData: apiData.getData.bind(apiData) });
    } else {
      throw new Error(
        '`useApiData` received an unexpected type for `endpoint`. Expected a string or a function.',
      );
    }

    getDataResponse
      .then((data) => {
        dispatch({ type: 'REQUEST_SUCCESS', data });
      })
      .catch((error: APIError) => {
        dispatch({ type: 'REQUEST_ERROR', error });
      });
  }, [endpoint, apiData, _options]);

  useEffect(() => {
    if (endpoint) {
      dispatch({ type: 'REQUEST_START' });

      fetchDataWrapper(); // Call fetchDataWrapper only if the endpoint is valid.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  // Return the state based on loading and error conditions
  if (state.status === 'loading') {
    return {
      data: null,
      loading: true,
      error: undefined,
      refetch: fetchDataWrapper,
    };
  }

  if (state.status === 'error') {
    return {
      data: null,
      loading: false,
      error: state.error,
      refetch: fetchDataWrapper,
    };
  }

  // This must be the success case
  return {
    data: state.data as T,
    loading: false,
    error: undefined,
    refetch: fetchDataWrapper,
  };
}
