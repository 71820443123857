import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useApiMutation } from 'api';
import { Modal } from 'components/Modal';
import { useAppDispatch } from 'store/createStore';
import { setTranslations } from 'store/features/translationsSlice';
import { isProd } from 'utils';

import Translation from '../Translation';

import {
  AcceptChangeLabel,
  EditLabel,
  ModalContent,
} from './EditTranslation.styles';

export type EditTranslationProps = {
  id?: string;
  closeModal: () => void;
};

const EditTranslation = ({ id, closeModal }: EditTranslationProps) => {
  const intl = useIntl();
  const currentTranslation = intl.formatMessage({ id });
  const dispatch = useAppDispatch();

  const [value, setValue] = useState(currentTranslation);
  const [acceptedChange, setAcceptedChange] = useState(false);

  const [editTranslation] = useApiMutation(
    'PATCH',
    '/api/v1/core-lang/r2/update-label/',
  );

  useEffect(() => setAcceptedChange(false), [value, setAcceptedChange]);

  const saveNewTranslation = async () => {
    try {
      await editTranslation({
        elementName: id,
        elementContent: value,
      });

      closeModal();

      await dispatch(
        setTranslations({ locale: 'en-gb', translations: undefined }),
      );
    } catch (e) {}
  };

  return (
    <Modal
      open
      modalHeading={`Editing label with id "${id}"`}
      primaryButtonText={<Translation id="common.save" />}
      primaryButtonDisabled={value === currentTranslation || !acceptedChange}
      onRequestSubmit={saveNewTranslation}
      secondaryButtonText={<Translation id="common.cancel" />}
      onSecondarySubmit={closeModal}
      onRequestClose={closeModal}
    >
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <EditLabel>
          New label:
          <textarea value={value} onChange={(e) => setValue(e.target.value)} />
        </EditLabel>
        <AcceptChangeLabel>
          <input
            type="checkbox"
            checked={acceptedChange}
            onChange={() => setAcceptedChange(!acceptedChange)}
          />
          <span>
            I understand that this change will edit the label in{' '}
            <b>
              {process.env.NODE_ENV} {isProd ? '(live platform)' : ''}
            </b>
          </span>
        </AcceptChangeLabel>
      </ModalContent>
    </Modal>
  );
};

export default EditTranslation;
