import React, { MouseEvent, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';
import { useFeatureFlag } from 'feature-flags';
import OrganisationChooser from 'pages/Settings/Clients/OrganisationChooser';
import { logout } from 'routes/components/Logout';
import { useOriginRouteContext } from 'routes/OriginRouteContext';
import { ACTIONS } from 'routes/reducer';
import { useAppDispatch, useAppSelector } from 'store/createStore';
import {
  clearDelegateAccessClientId,
  selectClientInfo,
  selectUserInfo,
  setFullPageLoadingScreen,
} from 'store/features/mainSlice';
import { useDirection } from 'utils/hooks';

import {
  CarbonOverflowMenu,
  MenuItem,
  UserDropdownParentContainer,
} from './UserDropdown.styles';

export interface Props {
  useImprovedDelegatedAccess: boolean;
}

const UserDropdown = ({ useImprovedDelegatedAccess }: Props) => {
  const location = useLocation();
  const direction = useDirection();
  const [organisationChooserOpen, setOrganisationChooserOpen] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const canViewOtherOrganisations = useAppSelector(
    (state) => state.main.canViewOtherOrganisations,
  );
  const userInfo = useAppSelector(selectUserInfo);
  const isCybSafeAdmin = userInfo?.userGroup === 1 || userInfo?.userGroup === 2;
  const clientInfo = useAppSelector(selectClientInfo);
  const navigate = useNavigate();
  const settings = useAppSelector((state) => state.userSettings);
  const { dispatch: originRouteContextDispatch } = useOriginRouteContext();
  const isConnectApp = localStorage.getItem('isConnectApp');
  const useOrgManagementV2 = useFeatureFlag('use-org-management-v2');
  const showChooseOrganisationV2 =
    canViewOtherOrganisations &&
    useOrgManagementV2 &&
    useImprovedDelegatedAccess;

  return (
    <>
      <UserDropdownParentContainer>
        <CarbonOverflowMenu
          id="userMenu"
          align="bottom"
          direction="bottom"
          flipped={direction !== 'rtl'}
          onOpen={() => {
            if (direction === 'rtl') {
              const floatMenu = document.querySelector(
                'ul.cds--overflow-menu-options',
              );
              if (floatMenu) floatMenu.classList.add('menu-rtl');
            }
          }}
        >
          <MenuItem
            itemText={intl.formatMessage({
              id: 'label_my_account',
            })}
            onClick={() => navigate('/settings/edit-profile')}
          />
          {canViewOtherOrganisations && (
            <MenuItem
              id="chooseOrganisation"
              aria-haspopup="dialog"
              itemText={intl.formatMessage({
                id: 'label_choose_organisation',
              })}
              onClick={() => setOrganisationChooserOpen(true)}
            />
          )}
          {showChooseOrganisationV2 && (
            <MenuItem
              id="chooseOrganisationV2"
              aria-haspopup="dialog"
              itemText={
                intl.formatMessage({
                  id: 'label_choose_organisation',
                }) + ' V2'
              }
              onClick={() => {
                originRouteContextDispatch({
                  type: ACTIONS.UPDATE,
                  payload: location.pathname,
                });
                navigate('/settings/organisation-management');
              }}
            />
          )}
          {settings.enableNotifications && (
            <MenuItem
              id="notificationPreferences"
              itemText={
                <Translation id="menu.userMenu.notificationPreferences" />
              }
              onClick={() => navigate('/notifications/preferences')}
            />
          )}
          {settings.enableNotifications && (
            <MenuItem
              id="notificationInbox"
              itemText={<Translation id="menu.userMenu.notificationInbox" />}
              onClick={() => navigate('/notification/inbox')}
            />
          )}
          {isCybSafeAdmin && (
            <MenuItem
              itemText={intl.formatMessage({
                id: 'menu.userMenu.openInCadmin',
              })}
              onClick={() => {
                window.open(`/cadmin/clients/client/${clientInfo.id}/change/`);
              }}
            />
          )}
          {isCybSafeAdmin && (
            <MenuItem
              itemText={intl.formatMessage({
                id: 'menu.userMenu.resetDelegatedAccess',
              })}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
                dispatch(
                  setFullPageLoadingScreen(
                    'loadingSpinner.resetDelegatedAccess',
                  ),
                );
                dispatch(clearDelegateAccessClientId());
                window.location.reload();
              }}
            />
          )}
          <MenuItem
            itemText={intl.formatMessage({
              id: 'label_help',
            })}
            onClick={() => {
              window.open('https://help.cybsafe.com/en/');
            }}
          />
          <MenuItem
            itemText={intl.formatMessage({
              id: 'footer.links.privacyPolicy',
            })}
            onClick={() => {
              window.open('https://www.cybsafe.com/website-privacy-policy/');
            }}
          />
          {clientInfo.isLogoutOn && (
            <MenuItem
              id="logout"
              itemText={intl.formatMessage({
                id: 'label_logout',
              })}
              onClick={() => {
                if (isConnectApp) {
                  (window as Window).ReactNativeWebView.postMessage(
                    JSON.stringify({ type: 'LOGOUT' }),
                  );
                } else {
                  logout(null);
                }
              }}
            />
          )}
        </CarbonOverflowMenu>
      </UserDropdownParentContainer>
      {canViewOtherOrganisations && (
        <OrganisationChooser
          open={organisationChooserOpen}
          onClose={() => {
            setOrganisationChooserOpen(false);
          }}
        />
      )}
    </>
  );
};

export default UserDropdown;
