import React from 'react';

import { Link } from 'react-router-dom';

import { FlexBox } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';

import {
  Container,
  RightContentWrapper,
  SubTitle,
  Title,
  TitleBlock,
} from './Wrapper.styles';

export interface WrapperProps {
  id?: string;
  title?: string | JSX.Element;
  $hideTitle?: boolean;
  dynamicTitle?: boolean;
  subtitle?: string | React.ReactNode;
  dynamicSubtitle?: boolean;
  callToAction?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  testId?: string;
  to?: string;
  titlePrefix?: JSX.Element;
  contentRight?: React.ReactNode;
  removePadding?: boolean;
  removeBorder?: boolean;
}

const Wrapper = ({
  id,
  title,
  $hideTitle = false,
  dynamicTitle,
  subtitle,
  dynamicSubtitle,
  callToAction,
  children,
  className,
  testId,
  to,
  titlePrefix,
  contentRight,
  removePadding,
  removeBorder,
}: WrapperProps) => {
  const shouldShowTitleBlock = () => {
    if (title && !$hideTitle) {
      return true;
    }
    if (subtitle !== undefined) {
      return true;
    }
    if (callToAction !== undefined) {
      return true;
    }
    return false;
  };

  return (
    <Container
      id={id}
      className={className}
      data-testid={testId}
      as={to && Link}
      to={to}
      $noPadding={removePadding}
      $removeBorder={removeBorder}
    >
      <FlexBox
        justify="space-between"
        align="center"
        gap="8px"
        {...(removePadding && { p: '24px' })}
      >
        {shouldShowTitleBlock() && (
          <TitleBlock>
            <>
              {title && !$hideTitle && (
                <FlexBox align="center">
                  {titlePrefix}
                  <Title $subtitle={subtitle}>
                    {dynamicTitle ? (
                      <span>{title}</span>
                    ) : (
                      <Translation id={title as string} />
                    )}
                  </Title>
                </FlexBox>
              )}
              {subtitle && (
                <SubTitle>
                  {dynamicSubtitle ? (
                    <span>{subtitle}</span>
                  ) : (
                    <Translation id={subtitle as string} />
                  )}
                </SubTitle>
              )}
            </>
            {callToAction && callToAction}
          </TitleBlock>
        )}
        {contentRight && (
          <RightContentWrapper>{contentRight}</RightContentWrapper>
        )}
      </FlexBox>
      {children}
    </Container>
  );
};

export default Wrapper;
