import { OverflowMenuItem } from '@carbon/react';
import styled from 'styled-components';

import OverflowMenu from 'components/OverflowMenu';
import { breakpoint } from 'theme';

export const UserDropdownParentContainer = styled.div`
  margin-left: 8px;
  display: inline-block;
  z-index: 100;
  flex: 0 0 auto;
  ${breakpoint('neatTablet')} {
    margin: 0;
  }
`;

export const CarbonOverflowMenu = styled(OverflowMenu)`
  & .cds--overflow-menu__icon {
    fill: ${({ theme }) => theme.componentColors.header.userMenuIconFill};
  }

  &.cds--overflow-menu:focus {
    outline: 2px solid
      ${({ theme }) => theme.componentColors.header.userMenuIconFillBorderFocus};
  }
  &.cds--overflow-menu:hover,
  &.cds--overflow-menu__trigger:hover {
    background: ${({ theme }) =>
      theme.componentColors.header.userMenuIconFillHover};
  }
  &.cds--overflow-menu.cds--overflow-menu--open,
  &.cds--overflow-menu-options {
    background: transparent;
  }
`;

export const MenuItem = styled(OverflowMenuItem)`
  .cds--overflow-menu-options__option-content {
    white-space: unset;
  }
  &.cds--overflow-menu-options__btn:focus {
    outline: 2px solid
      ${({ theme }) => theme.componentColors.header.userMenuIconFillBorderFocus};
  }
`;
