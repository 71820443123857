import { type Dispatch, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../createStore';

import { selectUserInfo } from './mainSlice';

export interface ConfigStateInterface {
  direction: DirectionType;
  locale: string;
  sentry: string;
  url: string;
  bottomBarHeight: number;
}

const initialState: ConfigStateInterface = {
  direction: 'ltr',
  locale: 'en-gb',
  sentry: '',
  url: '',
  bottomBarHeight: 0,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateTranslationConfig: (state, action) => {
      state.direction = action.payload.direction;
      state.locale = action.payload.locale;
    },
    updateBottomBarHeight: (state, action) => {
      state.bottomBarHeight = action.payload;
    },
  },
});

/* Actions */
export const { updateTranslationConfig, updateBottomBarHeight } =
  configSlice.actions;

/* Thunks */
export const updateConfigWithUserDefaultLanguage =
  (languages: Language[]) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const userDefaultLanguage = state.main.userInfo.defaultLanguage;
    const selectedLanguage = languages.filter(
      (lang: Language) => lang.shortName === userDefaultLanguage,
    );
    const languageSettings = {
      locale: userDefaultLanguage?.replace('_', '-'),
      direction: selectedLanguage[0]?.isrtl ? 'rtl' : 'ltr',
    };
    localStorage.setItem('language', JSON.stringify(languageSettings));
    dispatch(updateTranslationConfig(languageSettings));
  };
export const updateConfigWithStoredLanguage =
  (languages: Language[]) => (dispatch: Dispatch) => {
    const storedValue = localStorage.getItem('language');
    let languageSettings;
    if (storedValue) {
      const data = JSON.parse(storedValue);
      languageSettings = {
        locale: data.locale,
        direction: data.direction,
      };
    } else {
      const selectedLanguage = languages.filter(
        (lang) =>
          lang.shortName.split('-')[0] === navigator.language.split('-')[0],
      );
      languageSettings = {
        locale: selectedLanguage[0]?.shortName || 'en-GB',
        direction: selectedLanguage[0]?.isrtl ? 'rtl' : 'ltr',
      };
    }
    dispatch(updateTranslationConfig(languageSettings));
  };

/* Selectors */
export const selectConfigState = (state: RootState) => state.config;

export const selectLocale = createSelector(
  [selectConfigState, selectUserInfo],
  (config, userInfo) => {
    if (userInfo && userInfo.defaultLanguage) {
      return userInfo.defaultLanguage;
    }
    if (config.locale) {
      return config.locale;
    }
    return 'en_gb';
  },
);

export const selectBottomBarHeight = createSelector(
  [selectConfigState],
  (config) => config.bottomBarHeight ?? 0,
);
export default configSlice.reducer;
