import React from 'react';

import { CarbonLoading } from './LoadingSpinner.styles';

export type LoadingSpinnerProps = {
  size?: number;
  className?: string;
  withOverlay?: boolean;
  $type?: 'default' | 'white';
};

const LoadingSpinner = ({
  $type = 'default',
  className,
  withOverlay,
  size,
}: LoadingSpinnerProps) => (
  <CarbonLoading
    className={className}
    withOverlay={withOverlay || false}
    size={size || 3}
    $type={$type}
  />
);

export default LoadingSpinner;
