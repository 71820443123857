const __DEV__ = process.env.NODE_ENV !== 'production';

// Keep a track of all the keys that we've warned about
const warnLog = {};
export function warnMissingFlag(key) {
  if (!__DEV__) {
    return;
  }

  if (warnLog[key] !== undefined) {
    // We've already warned about this key before so bail out
    return;
  }

  warnLog[key] = true;
  // eslint-disable-next-line no-console
  console.info(
    `🚨🚨🚨 Trying to use feature flag \`${key}\` that does not exist. 🚨🚨🚨`,
  );
}
