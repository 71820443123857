import { useFeatureFlag } from 'feature-flags';
import { useAppSelector } from 'store/createStore';
import { selectDelegatedAccessClientId } from 'store/features/mainSlice';

const useHasDelegatedAccessEnabeld = () => {
  const delegatedAccessClientId = useAppSelector(selectDelegatedAccessClientId);
  const isDelegatedAccessFlagEnabled = useFeatureFlag(
    'use-delegated-access-header',
  );

  const hasDelegatedAccess =
    !!delegatedAccessClientId && isDelegatedAccessFlagEnabled;

  return hasDelegatedAccess;
};

export default useHasDelegatedAccessEnabeld;
