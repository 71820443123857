import React, { useEffect, useState } from 'react';

import FocusTrap from 'focus-trap-react';

import { useSubscriptionContext } from 'components/Subscription';
import { useAppSelector } from 'store/createStore';
import { selectBottomBarHeight } from 'store/features/configSlice';
import { selectClientInfo, selectUserInfo } from 'store/features/mainSlice';

import { WidgetContainer } from '../Widgets.styles';

import InsightsButton from './components/InsightsButton';
import InsightsContent from './components/InsightsContent';

export interface InsightsInterface {
  open?: boolean;
}

const Insights = ({ open = false }: InsightsInterface) => {
  const { hasNoSubscription } = useSubscriptionContext();
  const userInfo = useAppSelector(selectUserInfo);
  const clientInfo = useAppSelector(selectClientInfo);
  const bottomBarHeight = useAppSelector(selectBottomBarHeight);
  const [insightsOpen, setInsightsOpen] = useState(open);

  useEffect(() => {
    setInsightsOpen(open);
  }, [open]);

  if (!hasNoSubscription && clientInfo.isInsightsOn && !userInfo.isAdmin) {
    return (
      <FocusTrap
        active={insightsOpen}
        focusTrapOptions={{
          fallbackFocus: '#insights-popup',
          clickOutsideDeactivates: true,
        }}
      >
        <WidgetContainer
          data-testid="insights-popup"
          id="insights-popup"
          $bottomHeight={bottomBarHeight}
        >
          {insightsOpen && <InsightsContent />}
          <InsightsButton toggleOpen={setInsightsOpen} open={insightsOpen} />
        </WidgetContainer>
      </FocusTrap>
    );
  }
  return null;
};

export default Insights;
