import { useContext } from 'react';

import DirectionContext from 'DirectionContext';

/**
 * useDirection: Hook for getting the current direction.
 *
 * @returns {string} The current direction.
 * @see DirectionContext
 */
const useDirection = () => {
  return useContext<DirectionType>(DirectionContext);
};

export default useDirection;
