import React from 'react';

import { Box } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';

import Spinner from '../index';

import { CenteredSpacer } from './LoaderBlock.styles';

export type LoaderBlockProps = {
  text?: string;
  small?: boolean;
};

const LoaderBlock = ({ text, small }: LoaderBlockProps) => (
  <Box gap="16px" dis="flex" flexDirection="column">
    <CenteredSpacer data-testid="loader-block">
      <Spinner small={small} />
      {text && <Translation id={text} />}
    </CenteredSpacer>
  </Box>
);

export default LoaderBlock;
