import React, { Dispatch, useContext } from 'react';

import { Action } from './reducer';

type OriginRouteContextType = {
  state: {
    originRoute: string | null;
  };
  dispatch: Dispatch<Action>;
};

const OriginRouteContext = React.createContext<
  OriginRouteContextType | undefined
>(undefined);

export function useOriginRouteContext() {
  const context = useContext(OriginRouteContext);
  if (!context) {
    throw new Error(
      'useOriginRouteContext must be used within a OriginRouteProvider',
    );
  }
  return context;
}

export default OriginRouteContext;
