import { RootState } from 'store/createStore';

export function baseHeaders(state: RootState): HeadersInit {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json; charset=UTF-8',
  };

  const stateMain = state.main;
  const userInfo = stateMain.userInfo;

  if (
    stateMain.delegatedAccessClientId &&
    userInfo.client !== undefined &&
    stateMain.delegatedAccessClientId !== userInfo.client
  ) {
    headers['Cybsafe-Org-Override'] = `${stateMain.delegatedAccessClientId}`;
  }

  return headers;
}
