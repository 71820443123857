import React from 'react';

import Translation from 'components/Content/Translation/Translation';

import Spinner from '../Spinner';

import { Container } from './FullPageLoadingScreen.styles';

export type FullPageLoadingScreenProps = {
  description?: string;
};

export default function FullPageLoadingScreen({
  description,
}: FullPageLoadingScreenProps) {
  return (
    <Container>
      <Spinner />
      {description && <Translation id={description} defaultMessage="Loading" />}
    </Container>
  );
}
