import { keyframes } from 'styled-components';

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;
export const twistIn = keyframes`
  from {
    -webkit-transform: rotate(-90deg) scale(0.2);;
    transform: rotate(-90deg) scale(0.2);;
  }

  to {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
`;
export const pop = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`;
export const floatDown = keyframes`
  from {
    transform: translate3d(0,-20%,0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;
