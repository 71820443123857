import { DefaultTheme } from 'styled-components';

const getColors = (theme: DefaultTheme, colorPaletteKey: string) =>
  Object.keys(theme.colorPalette.ui[colorPaletteKey])
    .filter((key) => parseInt(key) > 500)
    .map((key) => theme.colorPalette.ui[colorPaletteKey][key]);

export const getAvatarColors = (theme: DefaultTheme) => {
  const corals = getColors(theme, 'coral');
  const blues = getColors(theme, 'blue');
  const navies = getColors(theme, 'navy');
  const pinks = getColors(theme, 'pink');
  const purples = getColors(theme, 'purple');
  const oranges = getColors(theme, 'orange');
  return [
    ...corals,
    ...blues,
    ...navies,
    ...pinks,
    ...purples,
    // filtering out single orange color that does not pass accessibility
    ...oranges.filter((key) => key !== theme.colorPalette.ui.orange[600]),
    theme.colorPalette.primary[500],
    theme.colorPalette.tones.neutral,
  ];
};

export const avatarColor = (
  theme: DefaultTheme,
  firstInitial: string,
  secondInitial: string,
) => {
  const avatarColors = getAvatarColors(theme);
  const firstCode = firstInitial.charCodeAt(0);
  const secondCode = secondInitial.charCodeAt(0);
  let initialsDiff =
    Math.max(firstCode, secondCode) % Math.min(firstCode, secondCode);

  if (initialsDiff > avatarColors.length) {
    initialsDiff %= avatarColors.length;
  } else if (initialsDiff === 0) {
    initialsDiff += 1;
  }

  return avatarColors[initialsDiff - 1];
};
