import React from 'react';

import { useAppSelector } from 'store/createStore';
import { selectUserInfo } from 'store/features/mainSlice';

import Beamer from './components/Beamer';
import LoggedInUser from './components/LoggedInUser';
import UserDropdown from './components/UserDropdown';
import { Menu } from './UserMenu.styles';

export interface UserMenuProps {
  useImprovedDelegatedAccess: boolean;
}

const UserMenu = ({ useImprovedDelegatedAccess }: UserMenuProps) => {
  const { isAdmin } = useAppSelector(selectUserInfo);
  const isConnectApp = localStorage.getItem('isConnectApp');
  return (
    <Menu>
      <LoggedInUser />
      {isAdmin && !isConnectApp && <Beamer />}
      <UserDropdown useImprovedDelegatedAccess={useImprovedDelegatedAccess} />
    </Menu>
  );
};

export default UserMenu;
