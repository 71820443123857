import React from 'react';

import Translation from 'components/Content/Translation/Translation';

import { Circle, Initials } from './UserInitials.styles';
import { UserInitialsProps } from './UserInitials.types';

const defaultInitialSize = {
  height: 21,
  lineHeight: 21,
  fontSize: 16,
};
const defaultCircleSize = {
  width: 40,
  height: 40,
  borderRadius: 24,
};

const UserInitials = ({
  firstName,
  lastName,
  className,
  circleSize = defaultCircleSize,
  initialSize = defaultInitialSize,
  avatarColorOverride,
  userProfileImage,
}: UserInitialsProps) => {
  const firstInitial =
    firstName && firstName.length !== 0 ? firstName.charAt(0) : ' ';
  const lastInitial =
    lastName && lastName.length !== 0 ? lastName.charAt(0) : ' ';
  return (
    <Circle
      $size={circleSize}
      $firstInitial={firstInitial}
      $secondInitial={lastInitial}
      className={className}
      $avatarColorOverride={avatarColorOverride}
      $userProfileImage={userProfileImage}
    >
      <span className="cds--visually-hidden">
        <Translation id="userMenu.userProfile.yourProfileLabel" />
      </span>
      {!userProfileImage && (
        <Initials
          aria-hidden="true"
          $size={initialSize}
        >{`${firstInitial}${lastInitial}`}</Initials>
      )}
    </Circle>
  );
};

export default UserInitials;
