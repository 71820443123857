import styled from 'styled-components';

export const UnderConstruction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    color: ${({ theme }) => theme.colorPalette.grey[100]};
  }
  h3 {
    font-size: ${({ theme }) => theme.headings.xs};
    font-weight: 600;
  }
`;
