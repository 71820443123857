import styled, { keyframes } from 'styled-components';

import { unit } from 'theme';

const iconSize = 24;

const themeIncomingAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: translateY(-14px) scale(0);
  }
  75% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const themeOutgoingAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-4px) scale(0.8);
  }
  100% {
    transform: translateY(12px) scale(0);
  }
`;

export const ThemeToggleButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${unit(1)} 1rem;
  gap: ${unit(1)};
  color: var(--cds-text-secondary, #525252);

  & svg {
    fill: var(--cds-icon-secondary, #525252);
  }
`;

export const IconsContainer = styled.div`
  height: ${iconSize}px;
  width: ${iconSize}px;
  overflow: hidden;
`;

export const IconIncoming = styled.div`
  position: absolute;
  animation-name: ${themeIncomingAnimation};
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
`;

export const IconOutgoing = styled.div`
  position: absolute;
  transform: scale(0);
  animation-name: ${themeOutgoingAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
`;
