import styled from 'styled-components';

import { unit } from 'theme';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${unit(3)} 0px;
  label {
    margin: ${unit(1)} 0 ${unit(1)} 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${unit(1)};
  gap: ${unit(1)};
`;
