import React from 'react';

import Translation from 'components/Content/Translation/Translation';
import { DarkModeIcon, LightModeIcon } from 'components/CustomIcons';
import { useAppDispatch, useAppSelector } from 'store/createStore';
import { selectThemeType, setTheme } from 'store/features/themeSlice';
import { usePrevious } from 'utils/hooks';

import {
  IconIncoming,
  IconOutgoing,
  IconsContainer,
  ThemeToggleButton,
} from './ThemeToggle.styles';

const getIcon = (themeType: 'light' | 'dark') => {
  switch (themeType) {
    case 'light':
      return <DarkModeIcon />;
    case 'dark':
      return <LightModeIcon />;
    default:
      return <></>;
  }
};

const ThemeToggle = () => {
  const dispatch = useAppDispatch();
  const themeType = useAppSelector(selectThemeType);
  const previousThemeType = usePrevious(themeType);
  return (
    <ThemeToggleButton
      id="theme-toggle-side-nav-btn"
      onClick={() =>
        dispatch(setTheme(themeType === 'light' ? 'dark' : 'light'))
      }
    >
      <Translation id="common.button.switchTheme" />
      <IconsContainer>
        <IconIncoming key={themeType + 'incoming'}>
          {getIcon(themeType)}
        </IconIncoming>
        <IconOutgoing key={previousThemeType + 'outgoing'} aria-hidden="true">
          {getIcon(previousThemeType)}
        </IconOutgoing>
      </IconsContainer>
    </ThemeToggleButton>
  );
};

export default ThemeToggle;
