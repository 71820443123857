import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage/session';

import configReducer from './features/configSlice';
import mainReducer from './features/mainSlice';
import settingsReducer from './features/settingsSlice';
import themeReducer from './features/themeSlice';
import translationsReducer from './features/translationsSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  stateReconciler: autoMergeLevel2,
};

export const rootReducer = combineReducers({
  config: configReducer,
  main: mainReducer,
  userSettings: settingsReducer,
  theme: themeReducer,
  translations: translationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    // This is a custom state transformer that will only send the necessary information to Sentry, no PII should be included here.
    const newState = {
      userInfo: {
        userId: state?.main?.userInfo?.id,
        isAdmin: state?.main?.userInfo?.isAdmin,
        isScim: state?.main?.userInfo?.isScim,
        timezone: state?.main?.userInfo?.timezone ?? 'unknown',
        language: state?.main?.userInfo?.defaultLanguage ?? 'unknown',
      },
      clientInfo: {
        clientId: state?.main?.clientInfo?.id,
        expiryDate: state?.main?.clientInfo?.expiryDate ?? 'unknown',
      },
      config: {
        delegatedAccessClientId: state?.main?.delegatedAccessClientId,
        featureFlags: state?.main?.featureFlags,
        theme: state?.theme?.type,
        userSettings: state?.userSettings,
      },
    };
    return newState;
  },
});

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 100 },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);

export default store;
