import styled from 'styled-components';

import { unit } from 'theme';

export const ShowLabelPopup = styled.div<{
  pos: { x: number; y: number };
  right: boolean;
  top: boolean;
}>`
  position: fixed;
  z-index: 999;

  left: ${({ pos }) => pos.x}px;
  top: ${({ pos }) => pos.y}px;

  transform: ${({ top, right }) =>
    [top && 'translateY(-100%)', right && 'translateX(-100%)']
      .filter((x) => !!x)
      .join(' ')};

  padding-top: ${unit(1)};

  > div {
    display: flex;
    gap: ${unit(1)};
    align-items: center;
    color: black;
    background: white;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 0.4);
    padding: ${unit(1)};

    > button {
      padding: ${unit(1)} ${unit(2)};
      min-height: 0px;
    }
  }
`;
