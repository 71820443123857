import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { unit } from 'theme';

export const ParentContainer = styled(Link)<{ $delegated: boolean }>`
  display: flex;
  color: ${({ theme, $delegated }) =>
    $delegated
      ? theme.colorPalette.core.white
      : theme.componentColors.bodyTextColor};
  background-color: ${({ theme, $delegated }) =>
    $delegated ? theme.colorPalette.primary[300] : 'unset'};
  padding: ${unit(1)};
  flex: 1 1 auto;
  justify-content: flex-end;
  min-width: 0;
  overflow: hidden;
`;

export const InfoContainer = styled.div`
  align-self: center;
  margin-right: 1rem;
  vertical-align: top;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const CompanyName = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: normal;
  line-height: 20px;
  text-align: right;
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
