import React from 'react';

import {
  Navigate,
  generatePath,
  useLocation,
  useParams,
} from 'react-router-dom';

type AltPathNavigateProps = {
  to: string;
};

const AltPathNavigate = ({ to }: AltPathNavigateProps) => {
  const location = useLocation();
  const params = useParams();

  return (
    <Navigate
      to={generatePath(to, params)}
      state={{ from: location }}
      replace
    />
  );
};

export default AltPathNavigate;
