import styled from 'styled-components';

import { unit } from 'theme';

export const StyledToastWrapper = styled.div<{ $hasDelegatedAccess: boolean }>`
  .toast-container {
    position: fixed;
    top: 64px;
    right: 0;
    padding: ${unit(2)};
    z-index: 9999;

    ${({ $hasDelegatedAccess }) =>
      $hasDelegatedAccess &&
      `
      top: calc(50px + 64px); // 50 height of delegated access banner
    `}
  }

  .toast {
    margin-bottom: ${unit(1)};
  }
`;
