import styled from 'styled-components';

import { Text } from 'components/Text';

export const InputWrapper = styled.div`
  width: 100%;

  & .cds--label {
    color: ${({ theme }) => theme.componentColors.bodyTextColor} !important;
  }

  // TODO: Remove bx parts when we move all selects over to new ones, stupid carbon

  & .cds--list-box__menu {
    background: ${({ theme }) =>
      theme.componentColors.inputField.dropdownBackgroundColor} !important;
  }

  & .cds--list-box__menu-item__option {
    color: ${({ theme }) => theme.componentColors.inputField.textColor};
  }

  & .cds--list-box__menu-item:hover {
    color: ${({ theme }) => theme.colorPalette.grey[800]};
  }

  & .cds--toggle-input__label {
    color: ${({ theme }) => theme.componentColors.bodyTextColor};
  }

  select:focus,
  button:focus {
    outline: 2px solid ${({ theme }) => theme.colorPalette.primary[300]};
  }

  input,
  textarea,
  select,
  .cds--dropdown,
  .cds--list-box {
    & button {
      & span {
        color: ${({ theme }) => theme.componentColors.inputField.textColor};
      }

      & span.cds--tag__label {
        color: ${({ theme }) => theme.colorPalette.core.white};
      }

      & .cds--tag__close-icon svg {
        fill: ${({ theme }) => theme.colorPalette.core.white} !important;
      }
    }
  }

  input:focus {
    outline: 2px solid ${({ theme }) => theme.colorPalette.primary[300]};
  }

  input::placeholder,
  input::-webkit-input-placeholder,
  input#groups-multi-select-input::placeholder {
    color: ${({ theme }) => theme.componentColors.inputField.textColor};
  }

  & button.multiselect-button {
    background: ${({ theme }) =>
      theme.componentColors.inputField.backgroundColor} !important;
    color: ${({ theme }) => theme.componentColors.inputField.textColor};

    svg {
      fill: ${({ theme }) => theme.componentColors.inputField.textColor};
    }

    &:disabled {
      color: ${({ theme }) => theme.colorPalette.grey[300]};
    }
  }
`;

export const FormErrorMessage = styled(Text)`
  color: var(--cds-support-error);
  font-size: ${({ theme }) => theme.fontSizes.s};
`;
