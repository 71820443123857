import { Tile } from '@carbon/react';
import styled from 'styled-components';

import { fadeInUp, floatDown } from 'animations';
import LoadingSpinner from 'components/Spinner/LoadingSpinner/LoadingSpinner';
import { unit } from 'theme';

export const ContentContainer = styled.div`
  border-radius: 10px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colorPalette.core.white};
  position: relative;
  height: 500px;
  width: 400px;
  margin-bottom: ${unit(3)};
  box-shadow:
    0 0 1px #4c56673f,
    0 2px 18px #1f253251;
  animation: 0.3s ${fadeInUp} ease-in-out;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  @media (max-width: 425px) {
    width: 95%;
    margin: ${unit(2)} auto;
  }
`;

export const ContentHeader = styled.div`
  height: 40%;
  > div {
    padding: ${unit(5)};
    height: 100%;
    color: ${({ theme }) => theme.colorPalette.core.white};
    background: linear-gradient(90deg, #674e6bff 0%, #4c3969ff 100%);
    p {
      font-size: ${({ theme }) => theme.fontSizes.l};
      animation: 1s ${fadeInUp} ease-in-out;
      &:first-of-type {
        font-size: ${({ theme }) => theme.headings.s};
        font-weight: 600;
        animation: 1s ${fadeInUp} ease-in-out;
      }
    }
  }
`;

export const Content = styled(Tile)`
  margin: -${unit(4)} ${unit(2)} ${unit(1)} ${unit(2)};
  padding: ${unit(2)};
  border-radius: 5px;
  animation: 0.3s ${floatDown} ease-in-out;
  animation-direction: alternate;
  .cds--tile-group .cds--tile--is-selected {
    border: 1px solid ${({ theme }) => theme.colorPalette.primary[300]};
    svg {
      fill: ${({ theme }) => theme.colorPalette.primary[300]};
    }
  }
  .cds--tile-group legend {
    visibility: hidden;
  }
`;

export const QuestionLoadingSpinner = styled(LoadingSpinner)`
  position: relative;
  left: 50%;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  margin-top: ${unit(2)};
  margin-bottom: ${unit(2)};
  padding: ${unit(1)};
  &:focus-visible {
    outline: ${({ theme }) => theme.colorPalette.primary[300]} auto 1px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${unit(2)};
`;

export const SubmissionMessage = styled.p`
  margin-top: ${unit(2)};
`;
