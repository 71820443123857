import React from 'react';

import { UserFilled } from '@carbon/icons-react';
import {
  Toggletip,
  ToggletipButton,
  ToggletipContent,
  ToggletipLabel,
} from '@carbon/react';
import { useIntl } from 'react-intl';

import { PersonalisationItem } from 'components/Component.types';
import Translation from 'components/Content/Translation/Translation';

import {
  StyledPersonalisationList,
  ToggletipIconWrapper,
  TooltipWrapper,
} from './BarItem.styles';

export interface PersonaliseBarItemProps {
  onPersonalisationSelected?: (val: string) => void;
  personalisationItems?: PersonalisationItem[];
  showPersonalisationIcon?: boolean;
}

const PersonaliseBarItem = ({
  onPersonalisationSelected,
  personalisationItems = [],
  showPersonalisationIcon,
}: PersonaliseBarItemProps) => {
  const intl = useIntl();
  return (
    <TooltipWrapper data-testid="personalisation-options">
      <Toggletip data-testid="wsyiwig-tooltip">
        <ToggletipButton
          label={intl.formatMessage({
            id: 'wsyiwig.editor.menu.title.personalise',
          })}
        >
          {showPersonalisationIcon && (
            <>
              <ToggletipIconWrapper data-testid="personalisation-trigger">
                <UserFilled size={16} />
              </ToggletipIconWrapper>
              <ToggletipLabel>
                <Translation id="wysiwyg.personalisationBar.description" />
              </ToggletipLabel>
            </>
          )}
        </ToggletipButton>
        <ToggletipContent>
          <StyledPersonalisationList>
            {personalisationItems.map((item, index) => (
              <li
                key={`${item.itemText}-${index}}`}
                data-testid={item.itemText}
                onClick={() =>
                  onPersonalisationSelected &&
                  onPersonalisationSelected(`{{${item.itemText}}}`)
                }
              >
                {item.displayText}
              </li>
            ))}
          </StyledPersonalisationList>
        </ToggletipContent>
      </Toggletip>
    </TooltipWrapper>
  );
};

export default PersonaliseBarItem;
