import React, { ComponentType } from 'react';

import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import { renderToString } from 'react-dom/server';

const allUrls =
  // eslint-disable-next-line
  /(https?:\/\/([-a-z0-9@:%._\+~#=]+\.?)*[-a-z0-9@:%._\+~#=]{2,256}\b([-a-z0-9@:%_\+.~#?&//=]*))/gi;
export const getLinkHtml = (strings: TemplateStringsArray) =>
  renderToString(
    <a href={strings[0]} target="_blank" rel="noopener noreferrer">
      {strings[0]}
    </a>,
  );
export const injectLinks = (htmlString: string) =>
  htmlString.replace(allUrls, getLinkHtml`$1`);
export const retry = (
  fn: () => Promise<{ default: ComponentType<any> }>,
  retriesLeft = 5,
  interval = 700,
) => {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
};
export const convertToSnakeCase = (str: string) => snakeCase(str);

export const convertFromSnakeCase = (str: string) =>
  str.replace(/_[a-z]/g, (letter) => letter.slice(1).toUpperCase());

export const convertToCamelCase = (str: string) => camelCase(str);

export const convertToKebabCase = (str: string) => kebabCase(str);

export const isLabel = (str: string) =>
  str.startsWith('label_') || /^[a-zA-Z]+(\.[a-zA-Z]+)+$/.test(str);

export const isDevEnv = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const isTestEnv = () => process.env.NODE_ENV === 'test';

export const isPreProdEnv = () =>
  !isDevEnv() &&
  !isTestEnv() &&
  window.location.href.startsWith('https://preprod');

export const isProd = process.env.NODE_ENV === 'production';

export const isE2E = process.env.REACT_APP_E2E === 'e2e';

export const compareByProperty =
  <T, Key extends keyof T>(key: Key) =>
  (a: T, b: T) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };

export const toTitleCase = (str: string) => {
  if (!str || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export function hasWindow(): boolean {
  return typeof window === 'object';
}

export const flattenArray = (arr: unknown[]) =>
  ([] as unknown[]).concat.apply([], arr);

export const generateRandomUniqueId = (): number =>
  Math.floor(Math.random() * 1000000);
