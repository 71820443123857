import styled from 'styled-components';

import { unit } from 'theme';

export const TooltipWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .cds--tooltip__trigger {
    padding: 8px;
  }

  & .cds--tooltip__trigger:not(.cds--btn--icon-only):focus {
    outline: none;
  }

  & .cds--tooltip__trigger:not(.cds--btn--icon-only) {
    font-size: 0.875rem;
  }

  & .cds--tooltip__label .cds--tooltip__trigger {
    margin-left: 0px;
  }

  & .cds--tooltip__trigger svg {
    margin-left: 0px;
  }

  & .cds--popover {
    min-width: 200px;
    width: auto;
`;

export const StyledPersonalisationList = styled.ul`
  & li {
    line-height: 18px;
    font-size: 0.875rem;

    &:hover {
      cursor: pointer;
      background: #534b4f;
    }
  }
`;

export const ToggletipIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${unit(1)};
`;
