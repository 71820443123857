import React, { useEffect } from 'react';

import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import styled from 'styled-components';

import { FallbackErrorComponent } from 'components/Error';
import FullPageLoadingScreen from 'components/FullPageLoadingScreen/FullPageLoadingScreen';
import ToastProvider from 'components/Toaster';
import DirectionContext from 'DirectionContext';
import { useFeatureFlag } from 'feature-flags';
import { useAppDispatch, useAppSelector } from 'store/createStore';
import {
  selectTranslationsForLocale,
  setTranslations,
} from 'store/features/translationsSlice';

import { useApiGet } from './api';
import MainRouter from './MainRouter';
import { OneSignalProvider } from './services/OneSignal/OneSignalProvider';
import { selectLocale } from './store/features/configSlice';
import { setFullPageErrorScreen } from './store/features/mainSlice';

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const Main = () => {
  const dispatch = useAppDispatch();
  const { direction } = useAppSelector((state) => state.config);
  const showFullPageLoadingScreen = useAppSelector(
    (state) => state.main.showFullPageLoadingScreen,
  );
  const cubeJwt = useAppSelector((state) => state.main.config.cubeJwt);
  const showFullPageErrorScreen = useAppSelector(
    (state) => state.main.showFullPageErrorScreen,
  );
  const useLabelSentryErrors = useFeatureFlag('use-label-sentry-errors');
  const locale: string =
    useAppSelector(selectLocale).replace?.('_', '-') || 'en-GB';

  const translations = useAppSelector((state) =>
    selectTranslationsForLocale(state, locale),
  );
  const translationsLoaded = !!translations;

  const [getTranslations] = useApiGet(`/api/v1/core-lang/r2/?locale=${locale}`);

  const cubeApi = cubejs(cubeJwt, {
    apiUrl: '/cube/v1',
  });

  useEffect(() => {
    const getTranslationsForLocale = async () => {
      try {
        const response = await getTranslations();
        dispatch(
          setTranslations({
            locale: locale.toLowerCase(),
            translations: response,
          }),
        );
      } catch (e) {
        dispatch(setFullPageErrorScreen(true));
      }
    };
    if (!translations) {
      getTranslationsForLocale();
    }
  }, [translations, locale, direction, dispatch, getTranslations]);

  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      messages={translations}
      textComponent="span"
      onError={(err) => {
        if (process.env.NODE_ENV === 'production') {
          useLabelSentryErrors && Sentry.captureException(err);
        } else {
          if (err.code === 'MISSING_TRANSLATION') return;
          throw err;
        }
      }}
    >
      <Helmet
        htmlAttributes={{ lang: locale }}
        bodyAttributes={{ dir: direction as string }}
      />
      <OneSignalProvider>
        <ToastProvider>
          <CubeProvider cubejsApi={cubeApi}>
            <DirectionContext.Provider value={direction}>
              {showFullPageErrorScreen ? (
                <FallbackErrorComponent />
              ) : showFullPageLoadingScreen || !translationsLoaded ? (
                <FullPageLoadingScreen />
              ) : (
                <Wrapper className="App">
                  <MainRouter />
                </Wrapper>
              )}
            </DirectionContext.Provider>
          </CubeProvider>
        </ToastProvider>
      </OneSignalProvider>
    </IntlProvider>
  );
};

export default Main;
