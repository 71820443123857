import { EffectCallback, useEffect } from 'react';

/**
 * useMount: Hook for running a function on mount.
 *
 * @param callback - The callback to run on mount.
 */
const useMount = (fn: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, []);
};

export default useMount;
