import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';

import { useApiGet } from 'api';
import { useAppDispatch, useAppSelector } from 'store/createStore';
import { selectBottomBarHeight } from 'store/features/configSlice';
import {
  clearDelegateAccessClientId,
  selectClientInfo,
  selectUserInfo,
  setFullPageLoadingScreen,
  setUrlFeaturesMap,
} from 'store/features/mainSlice';
import { selectLogo } from 'store/features/themeSlice';
import { useDirection, useHasDelegatedAccessEnabled } from 'utils/hooks';

import DelegatedAccessHeader from './components/DelegatedAccessHeader';
import Footer from './Footer';
import Header from './Header/Header';
import {
  Content,
  ContentWrapper,
  CustomHeader,
  Wrapper,
} from './Layout.styles';
import SideNav from './SideNav/SideNav';

export type LayoutProps = {
  title?: string;
  children: ReactElement;
  narrowWidth?: boolean;
  withUserMenu?: boolean;
  withSideMenu?: boolean;
  withTopMenu?: boolean;
  isTransparent?: boolean;
  noHeader?: boolean;
  useImprovedDelegatedAccess: boolean;
};

export type MenuItemType = {
  label: string;
  url: string;
  sub?: MenuItemType[];
  expanded?: boolean;
  active?: boolean;
  tags: Array<{ name: string; color: string }>;
  icon: string | null;
  itemPosition: string;
  isExternalLink: boolean | null;
  features?: string[];
};

const Layout = ({
  children,
  narrowWidth,
  withUserMenu,
  withSideMenu = false,
  withTopMenu = false,
  isTransparent,
  noHeader,
  useImprovedDelegatedAccess,
}: LayoutProps) => {
  const isConnectApp = localStorage.getItem('isConnectApp');
  const direction = useDirection();
  const location = useLocation();
  const [getMenuData, { data: menuData }] = useApiGet(
    '/api/v1/users/r3/menu/r3/',
    { cache: true },
  );

  const [isSideNavExpanded, setSideNavExpand] = useState(false);
  const { id: userId, isAdmin, userGroup } = useAppSelector(selectUserInfo);
  const bottomBarHeight = useAppSelector(selectBottomBarHeight);
  const logo = useAppSelector(selectLogo);
  const dispatch = useAppDispatch();
  const clientInfo = useAppSelector(selectClientInfo);

  const hasDelegatedAccessEnabled = useHasDelegatedAccessEnabled();

  useEffect(() => {
    async function fetchMenu() {
      // Fetch menu data only if user is logged in and any of the menu is enabled
      if (userId && (withUserMenu || withTopMenu || withSideMenu)) {
        await getMenuData();
      }
    }
    fetchMenu();
  }, [userId, withUserMenu, withTopMenu, withSideMenu, getMenuData]);

  useEffect(() => {
    if (isSideNavExpanded && isConnectApp) setSideNavExpand(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getMenuItems = useCallback(
    (value: string) =>
      menuData && typeof menuData === 'object' && value in menuData
        ? menuData[value]
        : [],
    [menuData],
  );

  const menuItems = useMemo(() => getMenuItems('topBar'), [getMenuItems]);
  const sideBarTopItems = useMemo(
    () => getMenuItems('sidebarTop'),
    [getMenuItems],
  );
  const sideBarBottomItems = useMemo(
    () => getMenuItems('sidebarBottom'),
    [getMenuItems],
  );

  return (
    <Wrapper $isTransparent={isTransparent}>
      <>
        {hasDelegatedAccessEnabled && (
          <DelegatedAccessHeader
            name={clientInfo.name ?? ''}
            onResetDelegatedAccess={async () => {
              dispatch(
                setFullPageLoadingScreen('loadingSpinner.resetDelegatedAccess'),
              );
              await dispatch(clearDelegateAccessClientId());
              const url = new URL(window.location.href);
              if (url.searchParams.has('delegated_access_client_id')) {
                url.searchParams.delete('delegated_access_client_id');
                window.history.pushState({}, '', url);
              }
              window.location.reload();
            }}
          />
        )}
        {!noHeader && (
          <CustomHeader
            aria-label="CybSafe"
            $delegatedAccess={hasDelegatedAccessEnabled}
          >
            <Header
              onClickSideNavExpand={setSideNavExpand}
              isSideNavExpanded={isSideNavExpanded}
              menuItems={menuItems}
              withTopMenu={withTopMenu}
              withUserMenu={withUserMenu}
              withSideMenu={withSideMenu}
              headerLogo={logo}
              useImprovedDelegatedAccess={useImprovedDelegatedAccess}
            />
            <SideNav
              isSideNavExpanded={isSideNavExpanded}
              menuItems={sideBarTopItems}
              menuBottomItems={sideBarBottomItems}
              headerItems={menuItems}
              hasCustomLogo={!!logo}
              withSideMenu={withSideMenu}
              withTopMenu={withTopMenu}
              direction={direction}
              isAdmin={isAdmin ?? false}
              userGroup={userGroup}
              hasDelegatedAccess={hasDelegatedAccessEnabled}
              updateFeatureMap={(urlFeatureMap) =>
                dispatch(setUrlFeaturesMap(urlFeatureMap))
              }
            />
          </CustomHeader>
        )}
        <ContentWrapper
          $isAdmin={isAdmin || userGroup === 7}
          $withSideMenu={withSideMenu}
          $direction={direction}
        >
          <Content
            id="main-content"
            narrowWidth={narrowWidth}
            noHeader={noHeader}
            $bottomHeight={bottomBarHeight}
            $hasDelegatedAccess={hasDelegatedAccessEnabled}
          >
            {children}
            <Footer />
          </Content>
        </ContentWrapper>
      </>
    </Wrapper>
  );
};

export default Layout;
