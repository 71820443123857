import { useLocation } from 'react-router-dom';

import store from 'store/createStore';
import {
  clearDelegateAccessClientId,
  setFullPageLoadingScreen,
} from 'store/features/mainSlice';
import { identifyUser } from 'tracking';
import { useMount } from 'utils/hooks';
import { removeAllCookies } from 'utils/token';

export const logout = (loginRedirectPath: string | null) => {
  identifyUser(undefined, undefined);
  store.dispatch(setFullPageLoadingScreen('loadingSpinner.logout'));
  store.dispatch(clearDelegateAccessClientId());
  if (loginRedirectPath) {
    localStorage.setItem('loginRedirectPath', loginRedirectPath);
  }
  removeAllCookies();
  window.location.href = '/login';
};

const Logout = ({ fullPath }: { fullPath?: string }) => {
  const location = useLocation();
  useMount(() => {
    logout(fullPath || location.pathname + location.search || null);
  });
  return null;
};

export default Logout;
