import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  HeaderGlobalBar,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderNavigation,
  SkipToContent,
} from '@carbon/react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';
import UserMenu from 'components/Layout/UserMenu/UserMenu';
import { useAppSelector } from 'store/createStore';
import { selectClientName } from 'store/features/mainSlice';

import { MenuItemType } from '../Layout';

import { Logo, LogoContainer, StyledHeaderName } from './Header.styles';

export interface HeaderProps {
  headerLogo?: string;
  isSideNavExpanded?: boolean;
  onClickSideNavExpand?: Dispatch<SetStateAction<boolean>>;
  menuItems: MenuItemType[];
  withUserMenu?: boolean;
  withTopMenu?: boolean;
  withSideMenu?: boolean;
  useImprovedDelegatedAccess: boolean;
}

export default function Header({
  headerLogo,
  onClickSideNavExpand,
  isSideNavExpanded,
  menuItems,
  withTopMenu,
  withUserMenu,
  withSideMenu,
  useImprovedDelegatedAccess,
}: HeaderProps) {
  const intl = useIntl();
  const [items, setItems] = useState<MenuItemType[]>([]);
  const location = useLocation();
  const clientName: string | undefined = useAppSelector(selectClientName);

  useEffect(() => {
    const menuItemsCopy = menuItems.slice();
    const formattedItems = menuItemsCopy.map((item) => {
      item.active = location.pathname === item?.url;
      return item;
    });
    setItems(formattedItems);
  }, [location.pathname, menuItems]);

  return (
    <>
      <SkipToContent>
        {intl.formatMessage({ id: 'header.skipToContent' })}
      </SkipToContent>
      {(withSideMenu || withTopMenu) && (
        <HeaderMenuButton
          id="expand-side-nav-btn"
          aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'}
          onClick={() =>
            onClickSideNavExpand && onClickSideNavExpand(!isSideNavExpanded)
          }
          isActive={isSideNavExpanded}
          data-testid="menu-button"
        />
      )}
      <StyledHeaderName as={Link} to="/" prefix="">
        <LogoContainer>
          <Logo
            src={
              headerLogo ||
              'https://cs-live-assets.s3.amazonaws.com/img/CybSafe-logo-Sept2020-01_1_aUzJiDK.png'
            }
            data-testid="CybSafe-logo"
            alt={intl.formatMessage(
              { id: 'page.header.logo.alt' },
              {
                clientName: clientName,
              },
            )}
          />
        </LogoContainer>
      </StyledHeaderName>
      {withTopMenu && items.length > 0 && (
        <HeaderNavigation aria-label="Navigation">
          {items.map((item) => (
            <Fragment key={item.url}>
              {item.isExternalLink ? (
                <HeaderMenuItem
                  id={`${item.label}-top`}
                  isActive={item.active}
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Translation id={item.label} />
                </HeaderMenuItem>
              ) : (
                <HeaderMenuItem
                  id={`${item.label}-top`}
                  isActive={item.active}
                  to={item.url}
                  as={Link}
                >
                  <Translation id={item.label} />
                </HeaderMenuItem>
              )}
            </Fragment>
          ))}
        </HeaderNavigation>
      )}
      {withUserMenu && (
        <HeaderGlobalBar>
          <UserMenu useImprovedDelegatedAccess={useImprovedDelegatedAccess} />
        </HeaderGlobalBar>
      )}
    </>
  );
}
