import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

import { pop, twistIn } from 'animations';
import { breakpoint, unit } from 'theme';

export const CircularButton = styled.button.attrs({
  'aria-haspopup': 'dialog',
})<{ isSupport?: boolean }>`
  align-self: flex-end;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  flex-shrink: 0;

  ${({ isSupport, theme }) =>
    isSupport
      ? css`
          background-color: ${theme.colorPalette.primary[300]};
          border: 2px solid ${theme.colorPalette.primary[300]};
          outline-color: ${theme.colorPalette.primary[300]};
        `
      : css`
          background-color: ${theme.colorPalette.tones.neutral};
          border: 2px solid ${theme.colorPalette.tones.neutral};
          outline-color: ${theme.colorPalette.tones.neutral};
        `}

  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ isSupport, theme }) =>
      isSupport
        ? theme.colorPalette.primary[500]
        : theme.colorPalette.ui.purple[700]};
    box-shadow:
      0 0 1px #4c56673f,
      0 2px 18px #1f253251;
  }
  ${breakpoint('800px')} {
    transform: scale(0.7) !important;
  }
`;

export const WidgetIcon = styled(FontAwesomeIcon)<{ $close?: boolean }>`
  color: ${({ theme }) => theme.colorPalette.core.white};
  font-size: 30px;
  animation: ${({ $close }) =>
    $close ? css`0.3s ${twistIn} ease-in` : css`0.3s ${pop} ease-out`};
`;

export const WidgetContainer = styled.div<{ $bottomHeight?: number }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  margin: ${unit(1)};
  bottom: ${(props) => (props.$bottomHeight ?? 0) + 16}px;
  right: ${unit(2)};
  z-index: 1000;
  max-height: 100%;
`;

export const PopupMessage = styled(WidgetContainer)<{ $bottomHeight?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: ${(props) => (props.$bottomHeight ?? 0) + 24}px;
  right: ${unit(11)};
  background-color: ${({ theme }) => theme.colorPalette.core.white};
  padding: ${unit(2)};
  border-radius: 5px;
  box-shadow:
    0 0 1px #4c56673f,
    0 2px 18px #1f253251;
  span {
    font-weight: 600;
    margin-right: ${unit(1)};
  }
  svg {
    color: ${({ theme }) => theme.colorPalette.primary[300]};
  }
`;
