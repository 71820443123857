import styled, { css } from 'styled-components';

import { avatarColor } from './UserInitials.helpers';
import { CircleSize, InitialSize } from './UserInitials.types';

export const Circle = styled.div<{
  $size: CircleSize;
  $firstInitial: string;
  $secondInitial: string;
  $avatarColorOverride?: string;
  $userProfileImage: string | null;
}>`
  display: inline-block;
  width: ${({ $size }) => $size.width}px;
  height: ${({ $size }) => $size.height}px;
  border-radius: ${({ $size }) => $size.borderRadius}px;
  ${({
    theme,
    $firstInitial,
    $secondInitial,
    $avatarColorOverride,
    $userProfileImage,
  }) =>
    !$userProfileImage &&
    css`
      background-color: ${!$avatarColorOverride
        ? avatarColor(theme, $firstInitial, $secondInitial)
        : $avatarColorOverride};
    `};

  ${({ $userProfileImage }) =>
    $userProfileImage &&
    `
    background-image: url(${$userProfileImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}
  text-align: center;
  position: relative;
  flex-shrink: 0;
`;

export const Initials = styled.span<{ $size: InitialSize }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: ${(props) => props.$size.height}px;
  line-height: ${(props) => props.$size.lineHeight}px;
  font-size: ${(props) => props.$size.fontSize}px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorPalette.core.white};
`;
