import { useEffect, useMemo } from 'react';

import { useAppSelector } from 'store/createStore';
import { selectUserInfo } from 'store/features/mainSlice';
import { UserInfo } from 'store/features/mainSliceTypes';
import { getCookie } from 'utils/token';

import { initializeOneSignal, setupTags } from './NotificationService';
import { useOneSignalContext } from './OneSignalProvider';

export type Config = Pick<
  UserInfo,
  'client' | 'defaultLanguage' | 'isAdmin'
> & {
  userId?: number;
};

const useInitializeNotificationService = () => {
  const hasCookie = getCookie() || false;
  const { enableNotifications } = useAppSelector((state) => state.userSettings);
  const { id, client, isAdmin, defaultLanguage } =
    useAppSelector(selectUserInfo);
  const { isInitialized, setIsInitialized } = useOneSignalContext();

  const config: Config = useMemo(
    () => ({
      userId: id,
      client: client ? client.toString() : undefined,
      defaultLanguage,
      isAdmin,
    }),
    [client, defaultLanguage, id, isAdmin],
  );

  useEffect(() => {
    if (hasCookie && enableNotifications && !isInitialized) {
      try {
        initializeOneSignal();
        setIsInitialized && setIsInitialized(true);
      } catch (err) {
        /* Error handled inside the init function */
      }
    }
  }, [
    isInitialized,
    setIsInitialized,
    hasCookie,
    client,
    isAdmin,
    id,
    defaultLanguage,
    enableNotifications,
  ]);

  useEffect(() => {
    if (isInitialized) {
      setupTags(config);
    }
  }, [config, isInitialized]);

  return null;
};

export default useInitializeNotificationService;
