import { useContext } from 'react';

import FeatureFlagsContext from './FeatureFlagsContext';
import { warnMissingFlag } from './utils';

export default function useFeatureFlag(key, _default = false) {
  const featureFlagsContext = useContext(FeatureFlagsContext);

  if (!featureFlagsContext) {
    return _default;
  }

  const flag = featureFlagsContext[key];

  if (!flag) {
    warnMissingFlag(key);
    return _default;
  }

  return flag.value;
}
