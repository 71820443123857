import React from 'react';

import { Helmet } from 'react-helmet-async';

import { isE2E, isProd } from 'utils';

const CookieConsent = () => {
  if ((isE2E && isProd) || !isProd) return null;

  return (
    <Helmet>
      <script
        type="text/javascript"
        src="https://cdn-ukwest.onetrust.com/consent/829b92ae-35e3-48ee-bc29-6447757213fb/OtAutoBlock.js"
      ></script>
      <script
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="829b92ae-35e3-48ee-bc29-6447757213fb"
      ></script>
    </Helmet>
  );
};

export default CookieConsent;
