import { useEffect, useRef } from 'react';

/**
 * usePrevious: Hook for getting the previous value of a variable
 *
 * @param value - The value to get the previous value of.
 * @returns - The previous value of the variable.
 */
const usePrevious = <T = undefined>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
