import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

export type OneSignalContextType = {
  isInitialized: boolean;
  setIsInitialized?: Dispatch<SetStateAction<boolean>>;
};

export const OneSignalContext = React.createContext<OneSignalContextType>({
  isInitialized: false,
});

export const useOneSignalContext = () => {
  const context = useContext(OneSignalContext);
  /* istanbul ignore next */
  if (!context) {
    throw new Error(
      'useOneSignalContext must be used within a OneSignalContext',
    );
  }
  return context;
};

export const OneSignalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const value = React.useMemo(
    () => ({ isInitialized, setIsInitialized }),
    [isInitialized, setIsInitialized],
  );

  return (
    <OneSignalContext.Provider value={value}>
      {children}
    </OneSignalContext.Provider>
  );
};
