import { TextArea } from '@carbon/react';
import styled from 'styled-components';

import { breakpoint } from 'theme';

export const TArea = styled(TextArea)`
  box-shadow: none !important;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colorPalette.primary[300]} !important;
    box-shadow: none !important;
  }
`;

export const ContainerWithSideLabel = styled.div`
  display: flex;
  width: 100%;

  > * {
    flex: 1;
    min-width: 200px;

    ${breakpoint('tablet')} {
      flex-basis: 100%;
    }
  }
`;
