import { Loading } from '@carbon/react';
import styled from 'styled-components';

import { LoadingSpinnerProps } from './LoadingSpinner';

export const CarbonLoading = styled(Loading)<LoadingSpinnerProps>`
  && {
    height: ${({ size }) => size}em;
    width: ${({ size }) => size}em;
  }

  ${({ $type, theme }) =>
    $type === 'white' &&
    `
      .cds--loading__stroke {
        stroke: ${theme.colorPalette.core.white};
      }
  `}
`;
