import React from 'react';

import { useIntl } from 'react-intl';

import Button from 'components/Button';
import Translation from 'components/Content/Translation/Translation';
import { Checkbox } from 'components/Form/Inputs';
import {
  ButtonContainer,
  TextArea,
} from 'components/Widgets/Insights/components/Insights.styles';

import { InsightsStateInterface } from '../Insights.types';

interface FeedbackSectionInterface {
  setInsight: (
    state: (state: InsightsStateInterface) => InsightsStateInterface,
  ) => void;
  submitInsight: () => void;
  disableSubmitBtn: boolean;
}

const FeedbackSection = ({
  setInsight,
  submitInsight,
  disableSubmitBtn,
}: FeedbackSectionInterface) => {
  const intl = useIntl();
  return (
    <>
      <p id="insights-feedback-label">
        <Translation id="component.insights.feedback" />
        <span className="cds--visually-hidden">
          {' '}
          <Translation id="common.required" />
        </span>
      </p>
      <TextArea
        id="feedback-textarea"
        aria-labelledby="insights-feedback-label"
        data-testid="feedback-textarea"
        placeholder={intl.formatMessage({
          id: 'component.insights.feedback.textArea.placeholder',
        })}
        onChange={(e) => {
          e.persist();
          setInsight((prevState) => ({
            ...prevState,
            feedback: e.target.value,
          }));
        }}
      />
      <Checkbox
        id="anonymous-checkbox"
        data-testid="anonymous-checkbox"
        labelText={
          <Translation id="component.insights.feedback.checkBox.label" />
        }
        onChange={(e, { checked }) => {
          setInsight((prevState) => ({
            ...prevState,
            anonymous: checked,
          }));
        }}
      />
      <ButtonContainer>
        <Button
          data-testid="submit-insights-btn"
          label="common.submit"
          disabled={disableSubmitBtn}
          onClick={() => {
            submitInsight();
          }}
        />
      </ButtonContainer>
    </>
  );
};

export default FeedbackSection;
