import React from 'react';

import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Translation from 'components/Content/Translation/Translation';
import { Wrapper } from 'components/Wrappers';
import { useFeatureFlag } from 'feature-flags';

import { UnderConstruction } from './FeatureFlagged.styles';

export type FeatureFlaggedProps = {
  featureFlag?: string;
  children: React.ReactNode;
};

const FeatureFlagged = ({ featureFlag, children }: FeatureFlaggedProps) => {
  const isFeatureFlagEnabled =
    useFeatureFlag(featureFlag) || featureFlag === undefined;

  return isFeatureFlagEnabled ? (
    <>{children}</>
  ) : (
    <Wrapper title="feature-flagged" $hideTitle>
      <UnderConstruction>
        <FontAwesomeIcon icon={faCogs} size="5x" />
        <h3>
          <Translation id="label_busyWorking" />
        </h3>
      </UnderConstruction>
    </Wrapper>
  );
};

export default FeatureFlagged;
