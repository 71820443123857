import styled from 'styled-components';

import { unit } from 'theme';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    font-size: ${({ theme }) => theme.fontSizes.s};
    word-break: break-word;
    span {
      font-weight: 600;
    }
  }
  img {
    height: 100%;
    max-height: 250px;
    max-width: 100%;
  }
`;

export const MobileErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${unit(2)};

  img {
    height: 100%;
    max-height: 250px;
    max-width: 100%;
  }
`;
