import React from 'react';

import {
  Checkbox as CarbonCheckbox,
  CheckboxProps as CarbonCheckboxProps,
} from '@carbon/react';

import CheckboxSkeleton from '../CheckboxSkeleton';

type CheckboxProps = CarbonCheckboxProps & {
  loading?: boolean;
};

const Checkbox = ({ loading, ...rest }: CheckboxProps) =>
  loading ? <CheckboxSkeleton /> : <CarbonCheckbox {...rest} />;

export { type CheckboxProps };
export default Checkbox;
