import React from 'react';

// This can be used in the future with shouldForwardProp on StyleSheetManager from styled-components. Once we have cleaned our components and send less props to DOM elements.
// import isPropValid from '@emotion/is-prop-valid';
// Note using isPropValid does not work as things like labelText are split out before they are used
import {
  DefaultTheme,
  StyleSheetManager,
  ThemeProvider,
} from 'styled-components';

type CSThemeProviderProps = {
  children?: React.ReactNode;
  theme: DefaultTheme;
};

const CSThemeProvider = ({ children, theme }: CSThemeProviderProps) => {
  return (
    <ThemeProvider theme={theme}>
      <StyleSheetManager
        shouldForwardProp={(prop) => !['index'].includes(prop)}
      >
        {children}
      </StyleSheetManager>
    </ThemeProvider>
  );
};

export default CSThemeProvider;
