import React, { ReactNode } from 'react';

import { StyledNotification } from './InlineNotification.styles';

export type InlineNotificationProps = {
  /**
   * CSS Classes to extend styling.
   */
  className?: string;
  /**
   * ID of notification.
   */
  id?: string;
  /**
   * Notification type to modify appearance
   */
  type: 'error' | 'info' | 'success' | 'warning';
  /**
   * The title of the notification.
   */
  titleLabel?: string;
  /**
   * The subtitle of the notification.
   */
  subtitleLabel?: string;
  /**
   * Function called when closing the notification.
   */
  onCloseHandler?: () => void;
  /**
   * Percentage value to modify the width of the notification.
   */
  maxWidth?: number | string;
  /**
   * Value to display or hide the close notification button.
   */
  hideCloseButton?: boolean;
  /**
   * A component that is displayed within the notification, typically for action events (Right-aligned).
   */
  children?: ReactNode;
};

const InlineNotification = ({
  className,
  id,
  type,
  titleLabel,
  subtitleLabel,
  onCloseHandler,
  maxWidth,
  hideCloseButton,
  children,
  ...rest
}: InlineNotificationProps) => (
  <StyledNotification
    className={className}
    id={id || `${type}-notification`}
    $maxWidth={maxWidth}
    kind={type}
    title={titleLabel ?? ' '}
    subtitle={subtitleLabel}
    statusIconDescription=""
    onCloseButtonClick={() => {
      typeof onCloseHandler === 'function' && onCloseHandler();
    }}
    hideCloseButton={hideCloseButton}
    data-testid={rest['data-testid']}
  >
    {children}
  </StyledNotification>
);

export default InlineNotification;
